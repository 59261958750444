
@keyframes heartPulsate {
  0%   { transform: scale(0.1); opacity: 0.7; }
  50%   { transform: scale(0.13); opacity: 1; }
  100% { transform: scale(0.1); opacity: 0.7; }
}

@keyframes heartExplode {
  0%   { transform: scale(0.1); opacity: 1; }
  100% { transform: scale(1); opacity: 0; }
}

.index-layout {
	overflow: hidden;
	height: 100%;
	width: 100%;
}

.loading-screen {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1000;
	background-color: lighten($black, 6);
	transition: all 1000ms ease;
	img {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 800px;
		height: 800px;
		margin-left: -400px;
		margin-top: -400px;
		animation: heartPulsate 1200ms infinite;
	}
	&.out {
		opacity: 0;
		visibility: hidden;
		img {
			animation-duration: 1200ms;
			animation-fill-mode: forwards;
			animation-iteration-count: 1;
			animation-name: heartExplode;
		}
	}
}