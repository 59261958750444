button {
  border: none;
  -webkit-appearance: none;
  outline: none;
}

@mixin button($color, $val) {
  color: $white;
  padding: 21px 40px 21px 50px;
  text-decoration: none;
  border-radius: 3px;
  font-family: $font-vdk-medium;
  font-weight: 300;
  font-size: 18px;
  position: relative;
  font-weight: 400;
  overflow: none;
  background-color: $color;
  display: inline-block;
  cursor: pointer;
  transition: all 500ms ease;
  .no-touch &:hover {
    background-color: darken($color, $val);
  }

  &:before {
    content: '';
    position: absolute;
    left: 20px;
    top: 23px;
    width: 20px;
    height: 20px;
    background-image: url('../images/icons/arrow-right-white-solid.svg');
    transform: rotate(180deg);
  }
  &.read-more {
    .no-touch & {
      span {
        position: absolute;
        right: 20px;
        top: 50%;
        margin-top: -9px;
        img {
          width: 18px;
          height: 18px;
          transition: all 400ms ease;
          opacity: 0;
          transform: translate3d(-10px, 0, 0);
        }
      }
      &:hover {
        padding-right: 60px;
        span {
          right: 20px;
          img {
            transform: translate3d(0px, 0, 0);
            opacity: 1;
          }
        }
      }
    }
    .touch & {
      span {
        display: none;
      }
    }
  }
}

.links {
  @include paragraph-medium; // font-size:18/ line-height: 26
  display: inline-block;
  text-decoration: none;
  position: relative;
  overflow: none;
  font-family: $font-vdk-semiBold;
  background-color: $red;
  color: $white;
  border-radius: 3px;
  position: relative;
  padding: 20px 80px 20px 40px;
  font-weight: 700;
  text-decoration: none;
  transition: all 500ms ease;
  text-align: left;
  white-space: nowrap;


  &:after {
    content: '';
    position: absolute;
    right: 40px;
    top: 24px;
    width: 20px;
    height: 20px;
    background-image: url('../images/icons/arrow-right-white-solid.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    transition: all .3s ease-in;
  }

  &.icon-right-open:before {
    position: absolute;
    left: 0;
    top: 4px;
    margin: 0;
    font-size: 16px;
    text-indent: -5px;
  }
  &:hover:after {
    transform: translate(10px,0);
  }

  @media only screen and (min-height: 709px) and (max-height: 950px) {
    padding: 15px 62px 15px 22px;
    margin-top: $space * 3.5;
    font-size: 13px;

    &:after {
      top: 19px;
      width: 12px;
      height: 12px;
      right: 22px;
    }

    &:hover {
      padding-right: 62px;
      transition: none;
    }
  }

  @media only screen and (max-width: $tablet) {
    padding: 15px 62px 15px 22px;
    margin-top: $space * 3.5;
    font-size: 13px;

    &:after {
      top: 19px;
      width: 12px;
      height: 12px;
      right: 22px;
    }

    &:hover {
      padding-right: 62px;
      transition: none;
    }
  }

  @media only screen and (max-width: $mobile) {
    font-size: 13px;
    line-height: 17px;
    padding: 15px 62px 15px 22px;
    margin-top: $space * 3.5;

    &:after {
      top: 19px;
      width: 12px;
      height: 12px;
      right: 22px;
    }

    &:hover {
      padding-right: 62px;
      transition: none;
    }
  }

  @media only screen and (max-height: 710px) and (min-width: 1025px) {

    padding: 15px 62px 15px 22px;
    margin-top: $space * 3.5;
    font-size: 13px;

    &:after {
      top: 19px;
      width: 12px;
      height: 12px;
      right: 22px;
    }

    &:hover {
      padding-right: 62px;
      transition: none;
    }
  }
}

.results-see-more {
  font-family: $font-vdk-semiBold;
  font-size: 15px;
  line-height: 20px;
  color: $dark-blue-new;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 56px;
  padding: 18px 20px;
  background-color: $white;
  font-weight: 400;
  text-align: center;
  @extend %effect;

  &.in {
    @extend %in;
  }

  span {
    float: right;
    display: block;
    width: 24px;
    height: 26px;
    background: url('../images/icons/arrow-down-solid.svg');
    background-size: 100%;
    &:before {
      content:  none;
    }
  }

  @media only screen and (max-width: 767px) {

  }
  @media only screen and (min-width: $tablet) {
    display: none;
  }
}


.close-market-info {
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;
  height: 55px;
  width: 55px;
  line-height: 55px;
  z-index: 600;
  text-align: center;
  cursor: pointer;
  @media only screen and (max-width: 767px) {
    display: block;
  }
}
