#skulderseason-overlay {
  svg {
    max-width: 670px;
  }
}

#skulderseason-section {
  background: $dark-blue-new;
  overflow: hidden;

  @media only screen and (max-width: $mobile) {
    .fp-tableCell {
      vertical-align: top;
    }
  }

  .page-title {
    @include page-title;
    color: $white;
    right: -44px;
    top: 100px;
  }

  .container.section-container {
    height: 100%;

    header {
      @include columns(1, 2);
      margin-top: 10vh;

      h1 {
        @include headline-title;
        @extend %effect;
        color: $white;
        &.in {
          @extend %in;
        }
      }

      p {
        @include paragraph;
        margin: 0 0 20px 0;
        padding-right: 76px;
        color: $white;

        @extend %effect;

        &.in {
          @extend %in;
        }
      }

      .subtext-link-box {
        margin-top: $space * 8;
        @extend %effect;
        &.in {
          @extend %in;
        }
        @media only screen and (max-width: 1024px){
          margin-top: 0;
        }

        @media only screen and (min-height: 650px) and (max-height: 950px) {
          margin-top: 0;
        }

        @media only screen and (max-height: 710px) and (min-width: 1025px) {
          margin-top: 0;
        }
      }

      @media only screen and (max-width: 1024px) {
        margin-top: 12.5vh;

        h1 {
          font-size: 36px;
          line-height: 41px;
          margin: 0 0 50px 0;

          br {
            display: none;
          }
        }

        p {
          font-size: 15px;
          line-height: 22px;
          margin: 0 0 10px 0;
        }
      }


      @media only screen and (max-height: 710px) and (min-width: 1025px) {
        margin-top: 12.5vh;

        h1 {
          font-size: 36px;
          line-height: 41px;
          margin: 0 0 50px 0;

          br {
            display: none;
          }
        }

        p {
          font-size: 15px;
          line-height: 22px;
          margin: 0 0 10px 0;
        }
      }

      @media only screen and (max-width: $mobile) {
        @include columns-change(1);
        margin: 0;

        h1 {
          padding: 0;
          margin: 41px 0 29px 0;

          span {
            @include columns-change(1);
          }
        }

        p {
          padding: 0;
          font-size: 13px;

          &:nth-child(3),
          &:nth-child(4) {
            display: none;
          }
        }
      }
    }

    .graph-area {
      float: left;
      position: absolute;
      width: 50%;
      height: 100%;
      right: 0;
      top: 0;
      z-index: 1;

      @include columns(1, 2);
      padding: $space * 8 0 $space * 6;

      @extend %effect;
      &.in {
        @extend %in;
      }

      .bkg {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 80%;
        background: url('../images/backgrounds/bkg-section-3.png') no-repeat;
        background-position: center center;
        background-size: cover;

        @media only screen and (max-height: 710px) and (min-width: 1025px) {
          height: 75%;
        }
      }

      .box-wrapper {
        max-width: 418px;
        margin-left: 120px;
        height: 100%;
        @extend %effect;

        &.in {
          @extend %in;
        }

        .key-number-wrapper {
          height: 100%;
          padding: 60px 20px 20px 20px;
          background: rgba(0, 0, 0, 0.15);
          @extend %effect;

          &.in {
            @extend %in;
          }
        }

        .small-text {
          font-family: $font-vdk-medium;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0.2px;
          color: $white;
          z-index: 10;
          position: absolute;
          bottom: 3%;
        }

        .box-inner {
          position: relative;
          height: 100%;
          margin-bottom: $space * 1.5;
          border: 0;
          box-sizing: border-box;
          // Using inset box-shadow instead of border for sizing simplicity
          box-shadow: inset 0 0 0 11px transparent;

          // Required, since we're setting absolute on pseudo-elements
          position: relative;
          vertical-align: middle;

          .box-content {
            @extend %effect;

            &.in {
             @extend %in;
            }
          }

          &::before,
          &::after {
            box-sizing: inherit;
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
          }


          &.draw {
              transition: color 0.25s;

            &::before,
            &::after {
              // Set border to invisible, so we don't see a 4px border on a 0x0 element before the transition starts
              border: 11px solid transparent;
              width: 0;
              height: 0;
            }

            // This covers the top & right borders (expands right, then down)
            &::before {
              top: 0;
              left: 0;
            }

            // And this the bottom & left borders (expands left, then up)
            &::after {
              bottom: 0;
              right: 0;
            }

            // Hover styles
            &.hover::before,
            &.hover::after {
              width: 100%;
              height: 100%;
            }

            &.hover::before {
              border-top-color: $white; // Make borders visible
              border-right-color: $white;
              transition:
                width 0.25s ease-out, // Width expands first
                height 0.25s ease-out 0.25s; // And then height
            }

            &.hover::after {
              border-bottom-color: $white; // Make borders visible
              border-left-color: $white;
              transition:
                border-color 0s ease-out 0.5s, // Wait for ::before to finish before showing border
                width 0.25s ease-out 0.5s, // And then exanding width
                height 0.25s ease-out 0.75s; // And finally height
            }
          }

          .key-number {
            margin-bottom: 10.5vh;

            span {
              margin-right: $space;
              font-family: $font-vdk-extraBold;
              font-weight: 800;
              font-size: 44px;
              line-height: 82px;
              letter-spacing: -3px;

              &.num {
                //font-size: 82px;
                line-height: 69px;
                font-size: 5.469em;
              }
            }

            &:nth-child(2) {
              span {
                line-height: 69px;
                line-height: 69px;
              }

              p {
                margin-top: $space * 2;
              }
            }

            p {
              @include paragraph-large;
              clear: both;
            }
          }

          .text {
            position: absolute;
            bottom: 15px;
            margin: -$space 0 $space * 2 0;
            font-family: $font-vdk-medium;
            font-weight: 400;
            font-size: 16px;
            line-height: 25px;
            letter-spacing: 0.4px;
          }
        }
      }

      @media only screen and (max-width: 1024px){
        padding: 80px 0 60px;
        .box-wrapper {
          max-height: 70%;
          margin-left: 0;
          .box-inner {

            .key-number-wrapper {
              padding: $space * 2;
            }

            .key-number {
              margin-bottom: 8vh;

              span {
                font-size: 34px;
                line-height: 62px;

                &.num {
                  font-size: 62px;
                  line-height: 62px;
                }
              }

              &:nth-child(2) {
                span {
                  font-size: 62px;
                  line-height: 62px;
                }
              }
            }
          }

          .small-text {
            font-size: 13px;
            line-height: 16px;
          }
        }
      }

      @media only screen and (min-height: 650px) and (max-height: 950px){
        .box-wrapper {
          .box-inner {

            .key-number-wrapper {
              padding: $space * 2;
            }

            .key-number {
              margin-bottom: 8vh;

              span {
                font-size: 34px;
                line-height: 62px;

                &.num {
                  font-size: 62px;
                  line-height: 62px;
                }
              }

              &:nth-child(2) {
                span {
                  font-size: 62px;
                  line-height: 62px;
                }
              }
            }
          }

          .small-text {
            font-size: 13px;
            line-height: 16px;
          }
        }
      }

      //@media only screen and (max-height: 710px) and (min-width: 1025px) {
        @media only screen and (max-height: 710px) and (min-width: 1025px) {
        padding-top: 40px;

        .box-wrapper {
          .box-inner {
            .key-number-wrapper {
              padding: $space * 2;
            }

            .key-number {
              margin-bottom: 5vh;

              span {
                font-size: 24px;
                line-height: 52px;

                &.num {
                  font-size: 52px;
                  line-height: 52px;
                }
              }

              &:nth-child(2) {
                span {
                  font-size: 52px;
                  line-height: 52px;
                }
              }

              p {
                font-size: 18px;
                line-height: 24px;
              }
            }

            .text {
              margin-bottom: 5px;
              padding-top: 0;
              font-size: 13px;
              line-height: 22px;
            }
          }

          .small-text {
            font-size: 13px;
            line-height: 16px;
          }
        }
      }


      @media only screen and (max-width: $mobile) {
        width: 100%;
        height: 100%;
        padding: 90px 15px 60px 15px;
        transform: translate3d(0, 100%, 0);
        //background: url('../images/backgrounds/bkg-section-3-mobile.png') no-repeat;
        background-position: left center;
        background-size: cover;

        .box-wrapper {
          height: 100%;
          margin-left: 0;

          .box-inner {
            height: 100%;
            padding: 0;

            .key-number-wrapper {
              padding: $space * 2 $space * 2.5 $space * 2 $space * 2.5;
            }

            .key-number {
              margin-bottom: 5vh;

              p {
                font-size: 16px;
                line-height: 18px;
              }

              span {
                font-size: 37px;
                line-height: 42px;

                &.num {
                  margin-right: $space;
                  font-size: 37px;
                  line-height: 42px;
                }
              }

              &:nth-child(2) {
                span {
                  font-size: 37px;
                  line-height: 42px;
                }
              }
            }

            .text {
              margin-top: 0;
              line-height: 18px;
            }
          }
        }
      }
    }
  }
}
