// This stylesheet generated by Transfonter (https://transfonter.org) on April 9, 2018

@font-face {
    font-family: 'VisitDenmark Outline';
    src: url('../fonts/VisitDenmarkOutline-ExtraBold.eot');
    src: url('../fonts/VisitDenmarkOutline-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/VisitDenmarkOutline-ExtraBold.woff2') format('woff2'),
        url('../fonts/VisitDenmarkOutline-ExtraBold.woff') format('woff'),
        url('../fonts/VisitDenmarkOutline-ExtraBold.ttf') format('truetype'),
        url('../fonts/VisitDenmarkOutline-ExtraBold.svg#VisitDenmarkOutline-ExtraBold') format('svg');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'VisitDenmark Regular';
    src: url('../fonts/VisitDenmarkSkrift-Regular.eot');
    src: url('../fonts/VisitDenmarkSkrift-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/VisitDenmarkSkrift-Regular.woff2') format('woff2'),
        url('../fonts/VisitDenmarkSkrift-Regular.woff') format('woff'),
        url('../fonts/VisitDenmarkSkrift-Regular.ttf') format('truetype'),
        url('../fonts/VisitDenmarkSkrift-Regular.svg#VisitDenmarkSkrift-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'VisitDenmark Medium';
    src: url('../fonts/VisitDenmarkSkrift-Medium.eot');
    src: url('../fonts/VisitDenmarkSkrift-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/VisitDenmarkSkrift-Medium.woff2') format('woff2'),
        url('../fonts/VisitDenmarkSkrift-Medium.woff') format('woff'),
        url('../fonts/VisitDenmarkSkrift-Medium.ttf') format('truetype'),
        url('../fonts/VisitDenmarkSkrift-Medium.svg#VisitDenmarkSkrift-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'VisitDenmark SemiBold';
    src: url('../fonts/VisitDenmarkSkrift-SemiBold.eot');
    src: url('../fonts/VisitDenmarkSkrift-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/VisitDenmarkSkrift-SemiBold.woff2') format('woff2'),
        url('../fonts/VisitDenmarkSkrift-SemiBold.woff') format('woff'),
        url('../fonts/VisitDenmarkSkrift-SemiBold.ttf') format('truetype'),
        url('../fonts/VisitDenmarkSkrift-SemiBold.svg#VisitDenmarkSkrift-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'VisitDenmark Bold';
    src: url('../fonts/VisitDenmarkSkrift-Bold.eot');
    src: url('../fonts/VisitDenmarkSkrift-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/VisitDenmarkSkrift-Bold.woff2') format('woff2'),
        url('../fonts/VisitDenmarkSkrift-Bold.woff') format('woff'),
        url('../fonts/VisitDenmarkSkrift-Bold.ttf') format('truetype'),
        url('../fonts/VisitDenmarkSkrift-Bold.svg#VisitDenmarkSkrift-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'VisitDenmark ExtraBold';
    src: url('../fonts/VisitDenmarkSkrift-ExtraBold.eot');
    src: url('../fonts/VisitDenmarkSkrift-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/VisitDenmarkSkrift-ExtraBold.woff2') format('woff2'),
        url('../fonts/VisitDenmarkSkrift-ExtraBold.woff') format('woff'),
        url('../fonts/VisitDenmarkSkrift-ExtraBold.ttf') format('truetype'),
        url('../fonts/VisitDenmarkSkrift-ExtraBold.svg#VisitDenmarkSkrift-ExtraBold') format('svg');
    font-weight: 800;
    font-style: normal;
}

.frame {
  .fp-tableCell {
    padding: 8px;

    .frame-inner {
      height: 100%;
      width: 100%;
      border: 6px solid $white;
    }
  }
}
.mobile {
  display: none;
}

@media only screen and (max-width: 767px) {
  .mobile {
    display: block;
  }
}

@mixin mobile {
  .desktop {
    display: block;
  }
  .mobile {
    display: none;
  }

  @media only screen and (max-width: 767px) {
    .desktop {
      display: none;
    }
    .mobile {
      display: block;
      margin: -16px auto 0 auto;
    }
  }
}

@mixin page-title {
  display: inline-block;
  float: right;
  position: absolute;
  top: 82px;
  right: -10px;
  margin: 0 0 0 10px;
  font-family: $font-vdk-medium;
  font-size: 18px;
  font-weight: 400;
  line-height: 31px;
  text-shadow: 0px 0px 60px rgba(0, 0, 0, 0.15);
  transform: rotate(90deg);
  z-index: 4;
  margin-left: 20px;

  @media only screen and (max-width: 767px) {
    display: none;
  }

  @media only screen and (max-height: 710px) and (min-width: 1025px) {
    top: 110px;
    padding-right: 40px;
  }
}

@mixin headline-large {
  font-size: 78px;
  line-height: 78px;
  letter-spacing: -2px;
  @media only screen and (max-width: 767px) {
    font-size: 40px;
    line-height: 46px;
  }
}

@mixin headline-small {
  font-size: 74px;
  line-height: 74px;
  letter-spacing: -2px;
  @media only screen and (max-width: 767px) {
    font-size: 34px;
    line-height: 36px;
  }
}
// Til markedsføringsresultater - Den er aaaaaalt for lang den overskrift
@mixin headline-xtra-small {
  font-size: 27px;
  line-height: 45px;
}

@mixin headline {
  font-size: 56px;
  line-height: 60px;
  //letter-spacing: -2px;
  //text-transform: uppercase;
  @media only screen and (max-width: 767px) {
    font-size: 32px;
    line-height: 38px;
  }
  @media only screen and (max-width: 767px-small) {
    font-size: 30px;
    line-height: 36px;
  }
}

@mixin headline-underline {
  position: relative;
  margin: 0 0 60px 0;
  font-size: 50px;
  line-height: 53px;
  letter-spacing: -2px;
  font-weight: 800;
  &:after {
    content: "";
    position: absolute;
    bottom: -30px;
    left: 0px;
    width: 80px;
    height: 5px;
    background-color: rgba($red, 1);
  }

  @media only screen and (max-width: 767px) {
    font-size: 28px;
    line-height: 32px;
  }
  @media only screen and (max-width: 767px-small) {
    font-size: 27px;
    line-height: 24px;
  }
}

@mixin headline-title {
  margin: 0 0 60px 0;
  // font-size: 50px;
  // line-height: 56px;
  font-size: 3.4em;
  line-height: 1.1;
  font-weight: 400;
  font-family: $font-vdk-bold;

   @media only screen and (max-height: 710px) and (min-width: 1025px) {
       margin: 0 0 50px 0;
   }

  @media only screen and (max-width: 767px) {
    font-size: 30px;
    line-height: 34px;
  }
}

@mixin headline-outline {
  margin: 0 0 120px 0;
  font-size: 39px;
  line-height: 50px;
  font-weight: 400;
  font-family: $font-vdk-outlineExtraBold;

   @media only screen and (max-height: 710px) and (min-width: 1025px) {
       margin: 0 0 60px 0;
   }

  @media only screen and (max-width: 767px) {
    margin: 0 80px 0;
    font-size: 43px;
    line-height: 55px;
  }
  @media only screen and (max-width: 767px-small) {
    font-size: 27px;
    line-height: 24px;
  }
}


@mixin headline-underline-white {
  color: $white;
  &:after {
    background-color: rgba($white, 1);
  }
  span {
    color: $red;
  }
}

@mixin headline-underline-blue {
  color: $white;
  &:after {
    background-color: rgba($white, 1);
  }
}

@mixin headline-not-underline {
  &:after {
    background: none;
  }
}

@mixin paragraph {
  font-family: $font-vdk-regular;
  font-weight: 400;
  //font-size: 18px;
  //line-height: 31px;
  font-size: 1.2em;
  line-height: 1.725;
  letter-spacing: 0.36px;
  color: $dark-blue-new;

  @media only screen and (min-height: 709px) and (max-height: 850px){
    font-size: 18px;
    line-height: 24px;
  }

  @media only screen and (max-height: 710px) and (min-width: 1025px) {
    font-size: 15px;
    line-height: 22px;
    margin: 0 0 10px 0;
  }

  @media only screen and (max-width: $mobile) {
    font-size: 13px;
    line-height: 23px;
    letter-spacing: 0.26px;
  }
}

@mixin paragraph-small {
  font-size: 15px;
  line-height: 24px;
  @media only screen and (max-width: 767px) {
    font-size: 13px;
    line-height: 18px;
  }
}

@mixin paragraph-xtra-small {
  font-size: 13px;
  line-height: 18px;
}

@mixin paragraph-medium {
  font-size: 18px;
  line-height: 26px;
  @media only screen and (max-width: 767px) {
    font-size: 14px;
    line-height: 21px;
  }
}

@mixin paragraph-xtra-medium {
  font-size: 20px;
  line-height: 27px;
  @media only screen and (max-width: 767px) {
    font-size: 16px;
    line-height: 21px;
  }
}

@mixin paragraph-large {
  font-size: 22px;
  line-height: 26px;
  font-weight: 400;
  font-family: $font-vdk-semiBold;
  @media only screen and (max-height: 710px) and (min-width: 1025px) {
    font-size: 20px;
    line-height: 26px;
  }
  @media only screen and (max-width: 767px) {
    font-size: 20px;
    line-height: 26px;
  }
}

@mixin paragraph-xlarge {
  font-size: 60px;
  line-height: 50px;
  @media only screen and (max-width: 767px) {
    font-size: 40px;
    line-height: 46px;
  }
}


html,
body,
input,
textarea,
button {
  @include paragraph-small;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //font-family: $font;
  font-family: $font-vdk-regular;
  font-weight: 400;
  color: $white;
}

html {
//  border: $grid-gutter * 2 solid $white;
}

strong {
  font-weight: bold;
}

em {
  font-style: italic;
}
