@keyframes alphaAnimation {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}

// Section styles
#turism-development {
  // background: -moz-linear-gradient(left, #f3f5f7 50%, #919191 50%);
  // background: -webkit-linear-gradient(left, #f3f5f7 50%, #919191 50%);
  // background: linear-gradient(to right, #f3f5f7 50%, #919191 50%);

  padding: 8px;

  .frame-inner {
    height: calc(100% - 16px);
    width: calc(100vw - 16px);
    position: absolute;
    top: 8px;
    left: 8px;
    z-index: 1;
    border: 6px solid $white;
  }

  .page-title {
    @include page-title;
    right: -30px;
    top: 95px;
  }

  .container {
    height: 100%;

  //  header {
      // @include columns(1, 2);
      // position: relative;
      // margin-top: 15vh;
      // z-index: 2;

      h1 {
        margin-top: 13.8vh;
        font-family: $font-vdk-outlineExtraBold;
        font-size: 90px;
        font-weight: 400;
        line-height: 91px;
        @extend %effect;
        color: $white;
        z-index: 5;
        &.in {
          @extend %in;
        }
      }

      @media only screen and (max-height: 710px) and (min-width: 1025px) {
        margin-top: 12.5vh;

        h1 {
          font-size: 50px;
          line-height: 56px;
          margin: 0 0 50px 0;
        }
      }

      @media only screen and (max-width: $mobile) {
        @include columns-change(1);
        margin: 0;

        h1 {
          position: absolute;
          padding: 0;
          margin: 41px 0 29px 0;
          font-size: 28px;
          font-weight: 400;
          line-height: 29px;
        }

        p {
          padding: 0;
          font-size: 13px;
          line-height: 20px;
          margin-bottom: 0;
        }
      }
    //}
  }

  .slideshow {
    //@include columns(1, 2);
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    padding: 0;
    z-index: 1;

    .img-wrapper {
      display: flex;
      width: 50%;
      position: relative;
      height: 100%;
      float: right;
      font-size: 0;
      justify-content: center;
      // animation: alphaAnimation 5s;

      @extend %effect;
      &.in {
        @extend %in;
      }

      img {
        display: flex;
        align-items: center;
        width: 90%;
        margin: 0 0 0 55px;
      }

      @media only screen and (max-height: 710px) and (min-width: 1025px) {
        padding-bottom: 10%;
      }

      @media only screen and (max-width: $mobile) {
        width: 100%;

        img {
          width: 100%;
          margin: 20px 0 0;
        }
      }

    }

    .fp-tableCell {
      vertical-align: bottom;
      background-color: rgba(8, 39, 71, .8);
    }

    .slide {
      position: relative;
      background-position: center bottom;
      background-size: cover;
      background-repeat: no-repeat;


      &:nth-child(3) {
        background-position: 10px center;
      }

      &:after {
        content: "";
        position: absolute;
        top: auto;
        right: 0px;
        bottom: 0px;
        height: 2px;
        left: 0px;
        transition: all 600ms ease;
        background-color: rgba($black, 0);
        z-index: 101;
      }
      &.in {
        &:after {
          height: 100%;
        }
      }

      @media only screen and (max-width: $mobile) {
        background-size: auto 100%;
      }

      .intro-wrapper {
        position: relative;
        float: left;
        width: 50%;
        height: 100%;

        @media only screen and (max-width: $mobile) {
          width: 100%;
          float: none;
        }
      }

      .intro {
        @extend %effect;
        padding: 45px;
        min-height: 404px;
        margin: 0;
        position: absolute;
        bottom: 52px;
        z-index: 102;
        background: #def3ff;
        border-radius: 10px;
        &.in {
          @extend %in;
        }

        h3 {
          font-family: $font-vdk-extraBold;
          font-weight: 800;
          font-size: 22px;
          line-height: 27px;
          margin: 0 0 30px 0;
          color: $dark-blue-new;
        }

        p {
          @include paragraph;
        }

        @media only screen and (max-height: 640px) and (min-width: 1025px) {
          p {
            font-size: 14px;
            line-height: 23px;
          }
        }

        @media only screen and (min-width: 768px) and (max-width: 1025px) {
          padding: 30px;
          h3 {
            margin: 0 0 30px 0;
          }
          p {
            font-size: 13px;
            line-height: 21px;
          }
        }

        @media only screen and (max-width: $mobile) {
          position: absolute;
          left: 50%;
          transform: translate(-50%, 80%) !important;
          width: 100%;
          max-width: none;
          margin: 0;
          padding: 23px 33px 33px 33px;
          transition: all 600ms ease;

          &:hover {
                transform: translate(-50%, 70px) !important;
          }

          h3 {
            margin-bottom: 25px;

            // .mobile {
            //   display: block;
            // }
            // .desktop {
            //   display: none;
            // }
          }

          p {
            font-size: 13px;
            line-height: 21px;

            // .mobile {
            //   display: block;
            // }
            // .desktop {
            //   display: none;
            // }
          }
        }
      }
    }
    .fp-controlArrow {
      left: auto;
      top: auto;
      bottom: 100px;
      right: calc(50% - 522px);
      width: 58px;
      height: 58px;
      border: 0;
      margin: 0;
      line-height: 60px;
      border: 2px solid $white;
      border-radius: 100%;
      text-align: center;

      &:hover {
        &:before {
          fill: $white;
        }
      }
      &:before {
        color: $white;
        @include icons-fonts;
        font-size: 23px;
        font-weight: 100;
        transition: opacity 0.3s, transform 0.6s ease, color 0.3s ease;
      }

      &.fp-prev {
        &:before {
          transform: translateY(200%);
          content: "\ea40";
        }
      }
      &.fp-next {
        right: calc(50% - 602px);

        &:before {
          text-indent: 3px;
          transform: translateY(-200%);
          content: "\ea3c";
        }
      }
      &.in {
        &:before {
          opacity: 1;
          transform: translateY(0%);
        }
      }

      @media only screen and (max-width: $tablet) {
        bottom: 100px;
        right: calc(50% - 240px);
        width: 38px;
        height: 38px;
        line-height: 36px;
        &.fp-prev {
        }
        &.fp-next {
          right: calc(50% - 302px);
        }
      }

      @media only screen and (max-width: $mobile) {
        display: none;
        right: 70px;
        left: auto;

        &:hover {
          &:before {
            color: $white;
          }
        }
        &.fp-prev {
          margin-top: -50px;
        }
        &.fp-next {
          margin-top: -115px;
        }
      }
    }

    @media only screen and (max-width: $mobile) {
      @include columns-change(1);
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
      margin-bottom: 0;
      border-left: 0;
      padding: 0;
      opacity: 0;
      transform: translate3d(0, 100%, 0);
      transition: all 600ms ease;
      &.in {
        opacity: 1;
        -ms-transform: none;
        transform: none;
      }
    }
  }

  .fp-slidesNav {
    &.bottom {
      right: calc(50% - 610px);
      left: inherit;
      margin: 0 !important;
      bottom: 60px;

      ul {
        margin: 0 auto;
        display: table;

        li {
          float: left;
          height: 6px;
          width: 100px;
          margin: 0 0 0 5px;

          &:first-child {
            margin-left: 0;
          }

          a {
            &.active,
            &:hover {
              span {
                background-color: $red;
              }
            }
            span {
              display: block;
              height: 100%;
              width: 100%;
              margin: 0;
              position: static;
              border-radius: 0;
              background-color: #fff;
              transition: background-color 0.3s ease;
            }
          }
        }
      }

      @media only screen and (max-width: $tablet) {
        right: calc(50% - 320px);

        ul {
          li {
            width: 50px;
          }
        }
      }

      @media only screen and (max-width: $mobile) {
        top: 120px;
        bottom: auto;
        width: 100%;
        left: 0;
        padding: 0 28px;
        opacity: 0;
        transform: translate3d(0, 50px, 0);
        transform: all 0.6s ease;

        &.bottom {
          margin: 0 !important;
        }

        &.in {
          opacity: 1;
          transform: none;
        }

        ul {
          width: 100%;
          li {
            width: 14.5%;
            height: 4px;
            margin: 0 0 0 2.5%;
            a,
            a span {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }

  .results-see-more {
    @extend %effect;
    @include paragraph-small;
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
    height: 40px;
    padding: 5px 10px;
    background-color: $dark-blue-new;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    span {
      margin: 0 5px;
      color: $red;
      font-size: 16px;
      font-weight: 600;
    }
    &.in {
      @extend %in;
    }
    @media only screen and (max-width: $mobile) {
      bottom: 34px;
    }
    @media only screen and (min-width: $tablet) {
      display: none;
    }
  }

  .close-market-info {
    position: absolute;
    top: 0;
    right: 0;
    height: 40px;
    width: 100%;
    line-height: 50px;
    background-color: $red;
    z-index: 600;
    text-align: center;
    cursor: pointer;
    img {
      width: 20px;
      height: 20px;
    }
    @media only screen and (min-width: $tablet) {
      display: none;
    }
  }
}
