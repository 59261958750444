#cases-overlay {
  padding: 8px;

  .page-title {
    z-index: 2;
    color: $white;
  }

  .frame-inner {
    height: calc(100% - 16px);
    width: calc(100vw - 16px);
    position: absolute;
    top: 8px;
    left: 8px;
    z-index: 1;
    border: 6px solid $white;
  }

  .slideshow.sub-page {
    //@include columns(1, 2);
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    padding: 0;
    z-index: 1;

    .fp-tableCell {
      vertical-align: bottom;
      padding-bottom: 105px;

      @media only screen and (max-width: $mobile) {
        padding-bottom: 10px;
        position: relative;
      }
    }

    .slide {
      position: relative;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;

      &:after {
        content: "";
        position: absolute;
        top: auto;
        right: 0px;
        bottom: 0px;
        height: 2px;
        left: 0px;
        transition: all 600ms ease;
        background-color: rgba($black, 0);
        z-index: 101;
      }
      &.in {
        &:after {
          height: 100%;
        }
      }

      &:nth-child(2),
      &:nth-child(5) {
          background-position: center center;
      }

      @media only screen and (max-width: $mobile) {
        background-size: auto 100%;
      }

      h1 {
        //margin: 13.8vh 40px 0 40px;
        position: absolute;
        top: 13.8vh;
        margin: 0 40px;
        font-family: $font-vdk-outlineExtraBold;
        font-size: 90px;
        font-weight: 400;
        line-height: 91px;
        //@extend %effect;
        color: $white;
        z-index: 5;
        // &.in {
        //   @extend %in;
        // }

        @media only screen and (max-height: 710px) and (min-width: 1025px) {
          top: 18vh;
          font-size: 50px;
          line-height: 56px;
          margin: 0 40px 50px 40px;
        }

        @media only screen and (max-width: $mobile) {
          position: absolute;
          padding: 0;
          top: 0;
          left: 20px;
          margin: 41px 0 29px 0;
          font-size: 28px;
          font-weight: 400;
          line-height: 29px;
        }
      }

      .intro {
        @extend %effect;
        padding: 45px 45px;
        width: 80%;
        max-width: 630px;
        min-height: 404px;
        margin: 40px 50px;
        //position: relative;
        position: absolute;
        bottom: 20px;
        z-index: 102;
        background: #f7e1e5;
        border-radius: 10px;
        &.in {
          @extend %in;
        }

        h3 {
          font-family: $font-vdk-extraBold;
          font-weight: 800;
          font-size: 22px;
          line-height: 27px;
          margin: 0 0 30px 0;
          color: $dark-blue-new;
        }

        p {
          @include paragraph;
        }


        @media only screen and (max-height: 710px) and (min-width: 1025px) {
          min-height: inherit;
          padding: 30px 45px;
          margin: 20px 50px 40px 50px;

          p {
            font-size: 15px;
            line-height: 20px;
          }
        }

        @media only screen and (max-height: 640px) and (min-width: 1025px) {
          p {
            font-size: 15px;
            line-height: 20px;
          }
        }

        @media only screen and (max-width: $mobile) {
          padding: 33px 33px;
          width: calc(100% - 56px);
          max-width: none;
          margin: 0 auto;
          box-sizing: border-box;
          min-width: 0;
          min-height: 0;
          bottom: -155px;
          z-index: 2;

          &:hover {
            height: auto;
            bottom: -20px;
          }

          p {
            font-size: 13px;
            line-height: 16px;
          }
        }

      }
    }
    .fp-controlArrow {
      left: auto;
      top: auto;
      bottom: 100px;
      border: 0;
      margin: 0;
      width: 58px;
      height: 58px;
      margin: 0;
      line-height: 60px;
      border: 2px solid $white;
      border-radius: 100%;
      text-align: center;

      &:hover {
        &:before {
          color: $red;
        }
      }
      &:before {
        @include icons-fonts;
        font-size: 23px;
        font-weight: 100;
        color: $white;
        opacity: 0;
        transition: opacity 0.3s, transform 0.6s ease, color 0.3s ease;
      }
      &.fp-prev {
        right: 220px;
        &:before {
          content: "\ea40";
          transform: translateY(200%);
        }
      }
      &.fp-next {
        right: 140px;

        &:before {
          content: "\ea3c";
          transform: translateY(-200%);
        }
      }
      &.in {
        &:before {
          opacity: 1;
          transform: translateY(0%);
        }
      }

      @media only screen and (max-width: $mobile) {
        right: 70px;
        left: auto;
        display: none;

        &:hover {
          &:before {
            color: $white;
          }
        }
        &.fp-prev {
          margin-top: -50px;
        }
        &.fp-next {
          margin-top: -115px;
        }
      }
    }

    @media only screen and (max-width: $mobile) {
      @include columns-change(1);
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
      margin-bottom: 0;
      border-left: 0;
      padding: 40px 0;
      opacity: 0;
      transform: translate3d(0, 100%, 0);
      transition: all 600ms ease;
      &.in {
        opacity: 1;
        -ms-transform: none;
        transform: none;
      }
    }
  }
}

#cases-section {
  background: $dark-blue-new;

  .page-title {
    @include page-title;
  }

  .container {
    header {
      @include columns(1, 2);
      margin-top: 10vh;

      h1 {
        @include headline-title;
        @extend %effect;

        &.in {
          @extend %in;
        }
      }
      p {
        @include paragraph;
        @extend %effect;
        margin: 0 0 20px 0;
        padding-right: 87px;
        color: $white;

        @media only screen and (max-width: $tablet) {
          padding-right: 0;
        }

        &.in {
          @extend %in;
        }
      }

      @media only screen and (max-height: 710px) and (min-width: 1025px) {
        margin-top: 4vh;

        h1 {
          font-size: 36px;
          line-height: 41px;
          margin: 0 0 30px 0;
        }
        p {
          font-size: 15px;
          line-height: 22px;
          margin: 0 0 10px 0;
        }
      }

       @media only screen and (max-width: $tablet) {
        margin-top: 4vh;

        h1 {
          font-size: 36px;
          line-height: 41px;
          margin: 0 0 30px 0;
        }
        p {
          font-size: 15px;
          line-height: 22px;
          margin: 0 0 10px 0;
        }
      }

      @media only screen and (max-width: $mobile) {
        @include columns-change(1);
        margin: 0;

        h1 {
          padding: 0;
          margin: 41px 0 29px 0;

          span {
            @include columns-change(1);
          }
        }

        p {
          padding: 0;
          font-size: 13px;
          line-height: 20px;
          margin-bottom: 0;

          span {
            display: none;
          }
        }
      }
    }
  }

  .right-content {
    @include columns(1, 2);
    position: absolute;
    width: 50%;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 8px;
    z-index: 1;
    //background-image: url('../images/backgrounds/bkg-section-8-right.png');
    background-image: url('../images/slides/Case-1.jpg');
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: cover;

  .slideshow {
    height: 100%;
    border: 6px solid $white;

    .subtext-link-box {
      height: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      padding-bottom: 90px;
      @extend %effect;
      &.in {
        @extend %in;
      }
    }

    .fp-tableCell {
      vertical-align: bottom;
      padding-bottom: 105px;
    }

    .fp-slides,
    .fp-controlArrow {
      display: none;
    }

    @media only screen and (max-width: $mobile) {
      .fp-slides,
      .fp-controlArrow {
        display: block;
      }
    }

    .slide {
      position: relative;
      background-position: center bottom;
      background-size: cover;
      background-repeat: no-repeat;

      &:after {
        content: "";
        position: absolute;
        top: auto;
        right: 0px;
        bottom: 0px;
        height: 2px;
        left: 0px;
        transition: all 600ms ease;
        background-color: rgba($black, 0);
        z-index: 101;
      }
      &.in {
        &:after {
          height: 100%;
        }
      }

      @media only screen and (max-width: $mobile) {
        background-size: auto 100%;
      }

      .intro {
        @extend %effect;
        padding: 0 20px;
        width: 80%;
        max-width: 490px;
        margin: 0 auto;
        position: relative;
        z-index: 102;
        &.in {
          @extend %in;
        }

        h3 {
          font-weight: 900;
          font-size: 17px;
          margin: 0 0 3px 0;
          text-transform: uppercase;
        }

        p {
          font-size: 17px;
          line-height: 25px;
        }

        @media only screen and (max-height: 640px) and (min-width: 1025px) {
          p {
            font-size: 15px;
            line-height: 20px;
          }
        }

        @media only screen and (max-width: $mobile) {
          width: 100%;
          max-width: none;
          margin-bottom: 20px;

          p {
            font-size: 13px;
            line-height: 16px;
          }
        }
      }
    }
    .fp-controlArrow {
      left: 20px;
      border: 0;
      margin: 0;

      &:hover {
        &:before {
          color: $red;
        }
      }
      &:before {
        font-family: "icons";
        font-style: normal;
        speak: none;
        display: inline-block;
        text-decoration: inherit;
        text-align: center;
        font-variant: normal;
        text-transform: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 23px;
        font-weight: 100;
        width: 50px;
        line-height: 48px;
        height: 50px;
        border: 2px solid #fff;
        border-radius: 100%;
        text-indent: -3px;
        opacity: 0;
        transition: opacity 0.3s, transform 0.6s ease, color 0.3s ease;
      }
      &.fp-prev {
        &:before {
          transform: translateY(200%);
        }
      }
      &.fp-next {
        margin-top: -65px;

        &:before {
          text-indent: 3px;
          transform: translateY(-200%);
        }
      }
      &.in {
        &:before {
          opacity: 1;
          transform: translateY(0%);
        }
      }

      @media only screen and (max-width: $mobile) {
        right: 70px;
        left: auto;

        &:hover {
          &:before {
            color: $white;
          }
        }
        &.fp-prev {
          margin-top: -50px;
        }
        &.fp-next {
          margin-top: -115px;
        }
      }
    }

    @media only screen and (max-width: $mobile) {
      @include columns-change(1);
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
      margin-bottom: 0;
      border-left: 0;
      padding: 40px 0;
      opacity: 0;
      transform: translate3d(0, 100%, 0);
      transition: all 600ms ease;
      &.in {
        opacity: 1;
        -ms-transform: none;
        transform: none;
      }
    }
  }
  @media only screen and (max-width: $mobile) {
    @include columns-change(1);
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    margin-bottom: 0;
    border-left: 0;
    padding: 40px 0;
    opacity: 0;
    transform: translate3d(0, 100%, 0);
    transition: all 600ms ease;
    &.in {
      opacity: 1;
      -ms-transform: none;
      transform: none;
    }
  }
}

  .fp-slidesNav {
    &.bottom {
      margin: 0 !important;
      bottom: 60px;
      right: 95px;
      left: auto;

      ul {
        margin: 0 auto;
        display: table;

        li {
          float: left;
          height: 4px;
          width: 70px;
          margin: 0 0 0 5px;

          &:first-child {
            margin-left: 0;
          }

          a {
            &.active,
            &:hover {
              span {
                background-color: $red;
              }
            }
            span {
              display: block;
              height: 100%;
              width: 100%;
              margin: 0;
              position: static;
              border-radius: 0;
              background-color: #fff;
              transition: background-color 0.3s ease;
            }
          }
        }
      }
      @media only screen and (max-width: $mobile) {
        width: 100%;
        left: 0;
        padding: 0 20px;
        opacity: 0;
        &.in {
          opacity: 1;
          transform: none;
        }

        ul {
          width: 100%;
          li {
            width: 14.5%;
            height: 6px;
            margin: 0 0 0 2.5%;
            a,
            a span {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
}

/* style for navigation in overlay cases (inpage slider) */
#cases-overlay + .fp-slidesNav {
  opacity: 0;
  transition: opacity 400ms;
}

#cases-overlay.in + .fp-slidesNav {
  opacity: 1;
  z-index: 1001;
  transition: opacity 800ms;
}

#cases-overlay .slideshow.sub-page .fp-controlArrow:before {
  color: #fff;
}

// mobile
#cases-overlay .hide-overlay {
  display: block;

  @media only screen and (max-width: $tablet) {
    display: none;
  }
}

#cases-overlay .close-market-info {
  display: none;

  @media only screen and (max-width: $mobile) {
    display: block;
    top: 15px;
    right: 15px;
  }
}

#cases-overlay .slideshow.sub-page.in {
  @media only screen and (max-width: $mobile) {
    padding: 0;
  }
}

#cases-section .fp-slidesNav.bottom {
  @media only screen and (max-width: $mobile) {
    top: 105px;
    bottom: auto;
    left: 0;
    right: auto;
  }
}

#cases-section .container {
  @media only screen and (max-width: $mobile) {

    padding: 0px 15px;
  }
}
