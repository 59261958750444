

.rotate-screen {
	z-index: 900;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: lighten($black, 8);
	display: none;
	@media only screen and (max-height: 400px) and (orientation: landscape) {
		display: block;
	}
	img {
		position: absolute;
		width: 44px;
		height: 36px;
		top: 50%;
		left: 50%;
		margin-left: -22px;
		margin-top: -28px;
	}
	p {
		@include paragraph-small;
		font-weight: 600;
		position: absolute;
		top: 50%;
		margin-top: 20px;
		width: 100%;
	}
}