// RESET
@import "vendor/reset";

// PLUGINS
@import "vendor/fullpage";

// BASE
@import "base/variables";
@import "base/grid";
@import "base/typography";
@import "base/extends.scss";
@import "base/buttons";
@import "base/navigation";
@import "base/overlay";
@import "base/article";
@import "base/loading-screen";
@import "base/rotate-screen";
@import "base/icons";

// Blocks
@import "blocks/intro";
@import "blocks/turism-key-numbers";
@import "blocks/chairmans-story";
@import "blocks/skulderseason";
@import "blocks/turism-development";
@import "blocks/results";
//@import "blocks/managements-story";
//@import "blocks/visitdenmark-key-numbers";
@import "blocks/digital-journey";
//@import "blocks/strategi";
@import "blocks/markets";
//@import "blocks/marketing-results";
@import "blocks/cases";
//@import "blocks/digital";
@import "blocks/effekt";
@import "blocks/cooperation";
//@import "blocks/press";
@import "blocks/footer";

// Pages
@import "pages/index";

// Classes, e.g. reusable components.
@import "classes/video";
@import "classes/hero-video";
