// Desktop height Breakpoints
$breakpoint-1: 800px;
$breakpoint-2: 700px;
$breakpoint-3: 590px;

// Overlay/subpage styles
#turism-key-numbers-overlay {
  .hide-overlay,
  .back-button {
    @include button($dark-blue-new, 6);
    font-weight: 600;
  }
  .overlay header {
    width: 100%;
    opacity: 1;
    h1 {
      color: $dark-blue-new;
      &:after {
        background-color: $black;
      }
    }
    p {
      opacity: 1;
      transform: none;
    }
  }
  svg,
  img {
    max-width: 670px;
  }
  i {
    font-style: italic;
  }
}

#turism-key-numbers {
  background-color: $light-blue;
  background-image: url('../images/backgrounds/bkg-section-2.png');
  background-size: 100%;
  background-position: center bottom;
  background-repeat: no-repeat;
  position: relative;

  @media only screen and (max-width: $mobile) {
    background-image: none;
    .container {
      height: 100%;
    }
  }

  .page-title {
    @include page-title;
    color: $dark-blue-new;
    right: -40px;
    top: 106px;
  }


  .container.section-container {

    header {
      float: left;
      margin-top: 80px;
      margin-bottom: 50px;
      margin-bottom: 6vh;

      @media only screen and (max-height: $breakpoint-1) and (min-width: 1025px) {
        margin-top: 60px;
        margin-top: 4vh;
        margin-bottom: 20px;
        margin-bottom: 3vh;
      }
      @media only screen and (max-height: $breakpoint-3) and (min-width: 1025px) {
        margin-top: 8vh;
        margin-bottom: 0;
      }
      @media only screen and (max-width: $mobile) {
        @include columns-change(1);
        width: 100%;
        padding: 0;
        margin: 0;
      }

      &.mobile-header {
        display: none;
        @media only screen and (max-width: $mobile) {
          margin-top: 0px;
          @include columns-change(1);
          padding: 20px 0 0 0;
          margin-bottom: 30px;
          display: block;
        }
      }

      h1 {
        @include columns(1);
        @include headline-title;
        @extend %effect;
        color: $dark-blue-new;
        margin-top: $space * 3;
        &:after {
          left: $space;
        }
        &.in {
          @extend %in;
        }

        @media only screen and (max-height: 710px) and (min-width: 1025px) {
          font-size: 36px;
          line-height: 41px;
          margin: 0 0 50px 0;
        }

        @media only screen and (max-height: $breakpoint-1) and (min-width: 1025px) {
          margin-top: $space * 2;
          margin-top: 3vh;

          span {
            @include columns-change(1);
          }
        }
        @media only screen and (max-height: $breakpoint-2) and (min-width: 1025px) {
          font-size: 45px;
          margin-bottom: 45px;

          &:after {
            bottom: -24px;
          }
        }
        @media only screen and (max-height: $breakpoint-3) and (min-width: 1025px) {
          font-size: 40px;
          margin-top: 5px;
          margin-bottom: 40px;

          &:after {
            bottom: -20px;
          }
        }
        @media only screen and (max-width: $mobile) {
          margin: 6.5vh 0 15px 0;

          span {
            @include columns-change(1);
          }

          br {
            display: none;
          }
        }
      }
      p {
        @include columns(1, 2);
        @include paragraph;
        padding-right: 70px;
        margin: 0 0 20px 0;
        @extend %effect;

        @media only screen and (max-width: $mobile) {
          @include columns-change(1);
          padding-right: 0;
          margin: 0 0 10px 0;

          &:nth-child(3) {
            display: none;
          }
        }
        @media only screen and (max-width: $mobile-small) {
          padding-right: 0;
          margin: 0 0 10px 0;
        }

        @media only screen and (max-height: 710px) and (min-width: 1025px) {
          font-size: 16px;
          line-height: 22px;
        }

        @media only screen and (max-height: $breakpoint-2) and (min-width: 1025px) {
          line-height: 25px;
          margin-bottom: 10px;
        }
        &.in {
          @extend %in;
        }
      }
      a {
        @media only screen and (max-width: $mobile) {
          //padding: 0 0 0 15px;

          &:before {
            top: 2px;
            font-size: 14px;
          }
        }
        &:before {
          color: $red;
        }
      }
    }
    .subtext-link-box {
      @include columns(1);
      margin: 10px 0 0 0;
      @extend %effect;
      @media only screen and (max-height: $breakpoint-1) and (min-width: 1025px) {
        margin-top: 0;
      }
      @media only screen and (max-width: $mobile) {
        margin-top: 0;
      }

      &.in {
        @extend %in;
      }
    }
    .key-number-list {
      @include columns(1);
      margin-bottom: 80px;
      margin-bottom: 10vh;

      @extend %effect;

      &.in {
        @extend %in;
      }

      @media only screen and (max-width: $mobile) {
        @include columns-change(1);
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
        margin-bottom: 0;
        border-left: 0;
        padding: 40px 30px;
        transform: translate3d(0, 100%, 0);
        background-color: $light-blue;
        background-image: url('../images/backgrounds/bkg-section-2-mobile.png');
        background-size: 100%;
        background-position: center bottom;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .key-number {
        @include columns-pure(2);
        @extend %effect;
        position: relative;
        border: 1px solid transparent;
        width: 50%;

        &.key-name-first {
          margin-bottom: 4vh;

          @media only screen and (max-width: $mobile) {
              margin-top: -18vh;
          }
        }


        .percent {
          position: relative;
          padding-left: 50px;
          font-family: $font-vdk-extraBold;
          font-size: 82px;
          line-height: 69px;
          font-weight: 400;
          color: #eef9ff;

          &:before {
            content: '';
            position: absolute;
            left: 0;
            width: 41px;
            height: 58px;
            background: url('../images/icons/arrow-up.svg');
          }

          span {
            float: none;
            font-family: $font-vdk-semiBold;
            font-size: 22px;
            line-height: 26px;
            font-weight: 400;
          }

          @media only screen and (max-height: 710px) and (min-width: 1025px) {
            font-size: 42px;
            line-height: 49px;

            &:before {
              top: 10px;
              width: 21px;
              height: 38px;
              background-size: 100%;
              background-repeat: no-repeat;
            }

            span {
              font-size: 22px;
              line-height: 26px;
            }
          }

          @media only screen and (max-width: $mobile) {
            font-size: 66px;
            font-weight: 400;
            line-height: 69px;
          }
         }

        &.in {
          @extend %in;
        }

        @media only screen and (max-width: $mobile) {
          @include columns-change(1);
          width: 100%;
          height: 30%;
          float: none;
          margin: 0;
          padding: 0px;
        }

        .key-number-inner {
          margin: 0 auto;
          width: 100%;
          position: relative;

          @media only screen and (max-width: $mobile) {
            display: table-cell;
            vertical-align: middle;
          }
        }

        .number {
          font-family: $font-vdk-extraBold;
          font-size: 82px;
          line-height: 96px;
          font-weight: 800;
          color: $dark-blue-new;
          clear: both;
          text-align: left;


          @media only screen and (max-width: $tablet) {
            @include columns-change(1);
            // font-size: 35px;
            // line-height: 35px;
          }

          @media only screen and (max-height: 710px) and (min-width: 1025px) {
            font-size: 60px;
            line-height: 63px;
          }

          @media only screen and (max-width: $mobile){
            font-size: 66px;
            line-height: 69px
          }

          .num,
          span {
            float: left;
            margin-left: 8px;
          }

          .text {
            font-size: 22px;
            line-height: 26px;
            font-family: $font-vdk-semiBold;
            clear: both;
          }
        }

        &.in {
          @media only screen and (max-width: $mobile) {
            opacity: 1;
            transform: none;
          }
          @media only screen and (max-width: $mobile +1) {
            opacity: 1;
            transform: none;
          }
        }
      }
    }
  }
}
