@keyframes overlayFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes overlayFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes overlaySlideIn {
  0% {
    transform: translate3d(100%, 0, 0);
  }
  100% {
    transform: none;
  }
}

@keyframes overlaySlideOut {
  0% {
    transform: none;
  }
  100% {
    transform: translate3d(100%, 0, 0);
  }
}

.overlay {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $medium-grey;
  color: $dark-blue-new;
  z-index: 1000;
  animation-duration: 700ms;
  animation-fill-mode: forwards;
  animation-name: overlaySlideIn;
  .overlay-header {
    position: absolute;
    top: 35px;
    left: 0;
    height: 66px;
    padding: 0 35px 10px 35px;
    z-index: 10;
    text-align: left;
    .container {
      position: relative;
      height: 100%;
    }
    .hide-overlay {
      @include button($dark-blue-new, 5);
      position: absolute;
      top: 50%;
      transform: translate3d(0, -50%, 0);
    }

    @media only screen and (max-width: $mobile) {
      top: 40px;
    }
  }
  .article-wrapper {
    position: absolute;
    left: 0;
    top: 100px;
    height: calc(100% - 100px);
    width: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
  &.in {
    animation: none;
  }
  &.out {
    animation-duration: 700ms;
    animation-fill-mode: forwards;
    animation-name: overlaySlideOut;
  }

  @media only screen and (max-width: 767px) {
    img {
      max-width: 100%;
    }
  }
}
