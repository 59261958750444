.hero-video,
.video-subpage {
  //display: none;
  height: auto;;
  margin-top:  150px;

  @media only screen and (min-width: 1025px) {
    display: block;
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    .hero-video-player,
    .subpage-video-player {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      min-height: 100%;
      min-width: 100%;
      background: #f5f5f5 none repeat scroll 0 0;
      overflow: hidden;
    }
    &:after {
      content: "";
      position: absolute;
      z-index: 101;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba($dark-earth, 0);
    }
  }
  img {
    width: 100%;
  }
}
