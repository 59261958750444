#digital-journey-section-overlay {
  &.overlay  {
    .overlay-header {
      background: none;
    }

    .article-wrapper {
      height: 100%;
      top: 0;

      .article {
        height: 100%;
        padding: 0;

        section {
          margin: 0;
          height: 100%;
        }

        .container {
          height: 100%;
          margin: inherit;
          max-width: inherit;
          padding: 0;

          .video {
            &.mobile {
              display: none;

              @media only screen and (max-width: $mobile) {
                display: block;

                .video-overlay .play-button {
                  transform: translate3d(-50%, -50%, 0);
                }
              }
            }
          }

          .subpage-video-player {
            display: block;

            @media only screen and (max-width: $mobile) {
              display: none;
            }
          }

          .video-subpage {
            left: 0;
            right: 0;
            margin-top: 0;
            overflow: hidden;

            video {
              width: 100%;
              background: $black;
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              min-height: 100%;
              min-width: 100%;
              overflow: hidden;
              top: 50%;
              transform: translateY(-50%)

            }

          }
        }
      }
    }

    @media only screen and (max-width: $mobile) {
      .article-wrapper {
        .article {
          .container {
            .video-subpage {
              .video-content{
                height: auto;
                width: 100%;
                padding-bottom: 0;
                margin-top: 19vh;
              }

              video {
                height: auto;
                width: 100%;
                top: inherit;
                transform: inherit;
                min-height: inherit;
              }

            }
          }
        }
      }
    }
  }
}

#digital-journey-section {
  background-color: $dark-blue-new;

  @media only screen and (max-width: $mobile) {
    .fp-tableCell {
      vertical-align: top;
    }
  }

  .page-title {
    @include page-title;
    right: -20px;
    top: 87px;
  }

  .container.section-container {
    height: 100%;

    .left-container,
    .right-container {
      position: absolute;
      height: 100%;
      width: 50%;
      top: 0;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% 100%;
      @include columns(1, 2);
    }

    .left-container {
      left: 0;
      background-image: url('../images/backgrounds/bkg-section-7-left.png');
    }

    @media only screen and (max-width: $mobile) {
      padding: 0;
      .left-container,
      .right-container {
        @include columns-change(1);
        position: inherit;
        width: 100%;
        padding: 15px;
        background-size: cover;
      }
    }

    header {
      max-width: 520px;
      margin: 10vh auto 0 auto;

      h1 {
        @include headline-title;
        @extend %effect;
        max-width: 80%;
        color: $white;

        &.in {
          @extend %in;
        }
      }
      p {
        @include paragraph;
        margin: 0 0 90px 0;
        color: $dark-blue-new;

        @extend %effect;

        &.in {
          @extend %in;
        }
      }
      .subtext-link-box {
        @extend %effect;
        &.in {
          @extend %in;
        }

        &:hover {
          transform: none;
          transition: none;
        }
      }

      @media only screen and (max-height: 710px) and (min-width: 1025px) {
        margin-top: 12.5vh;

        h1 {
          font-size: 36px;
          line-height: 41px;
          margin: 0 0 50px 0;

          br {
            display: none;
          }
        }
        p {
          font-size: 15px;
          line-height: 22px;
          margin: 0 0 10px 0;
        }
      }

      @media only screen and (max-width: $mobile) {
        @include columns-change(1);
        margin: 0;

        h1 {
          padding: 0;
          margin: 25px 0 29px 0;
          color: $dark-blue-new;

          span {
            @include columns-change(1);
          }
        }

        p {
          margin-bottom: 30px;
          padding: 0;
          font-size: 13px;

          span, br {
            display: none;
          }
        }
      }
    }

    .video {
      @extend %effect;

      &.in {
        @extend %in;
      }

      margin: 0 auto 30px auto;
      overflow: hidden;
      max-width: 519px;
      clear: both;

      @media only screen and (max-width: $mobile) {
        width: 100%;
      }

      img {
        display: block;
        width: 100%;
      }

      .links {
        width: 110px;
        height: 110px;
        padding: 0;
        margin: 0;
        text-align: center;
        line-height: 108px;
        border-radius: 100%;
        transform: translate3d(-50%, -50%, 0);
        text-transform: uppercase;

        &:hover {
          padding: 0;
        }

        &:after {
          content: none;
        }

        @media only screen and (max-width: $mobile) {
          width: 80px;
          height: 80px;
          line-height: 80px;
        }
      }

      video {
        object-fit: cover;
      }

      .video-overlay {
        display: flex !important;
        align-items: center;
        justify-content: center;

        .play-button {
          top: inherit;
          left: inherit;

          .links {
            transform: none;
          }
        }
      }

      @media only screen and (max-height: 710px) and (min-width: 1025px) {
        height: 200px;
        overflow: hidden;
        margin-bottom: 20px;

        .video-overlay,
        video {
          height: 100%;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .results-see-more {
      z-index: 10;
    }

    .right-container {
      right: 0;
      z-index: 3;

      padding: 80px 0 0;
      overflow: hidden;

      .right-container-inner {
        height: 100%;
        width: 100%;
        overflow: hidden;
        background-image: url('../images/backgrounds/bkg-section-7-right.jpg');
        background-size: cover;
        transform: scale(2.2);
        background-position: center center;

        > div {
          transform: scale(0.454);
        }
      }

      @media only screen and (max-height: 710px) and (min-width: 1025px) {
        //padding: 40px 0 0;
        padding: 0;
      }

      .box-wrapper {
        display: flex;
        height: 100%;
        margin: 0 auto;
        vertical-align: bottom;
        padding-bottom: 40px;

        .box-inner {
          margin: 0 auto;
          padding: 60px 20px 20px 20px;
          align-self: flex-end;
          @extend %effect;

          &.in {
            @extend %in;
          }

          @media only screen and (max-height: 710px) and (min-width: 1025px) {
            padding: 20px;
          }

          h2 {
            font-family: $font-vdk-outlineExtraBold;
            font-size: 76px;
            font-weight: 400px;
            line-height: 100px;
            text-align: center;

            span {
              font-family: $font-vdk-extraBold;
              font-size: 76px;
              font-weight: 800px;
              line-height: 80px;
              clear: both;
              display: block;
            }

            @media only screen and (max-height: 710px) and (min-width: 1025px) {
              font-size: 48px;
              line-height: 53px;

              span {
                font-size: 48px;
                line-height: 53px;
              }

              br {
                display: none;
              }
            }

            @media only screen and (max-width: $tablet) {
              font-size: 58px;
              line-height: 63px;

              span {
                font-size: 58px;
                line-height: 63px;
              }

              br {
                display: none;
              }
            }
          }

          .text {
            @include paragraph;
            text-align: center;
            max-width: 500px;
            margin: 0 auto;
            color: $white;
          }
      }
      }

      @media only screen and (max-width: $mobile) {

        @include columns-change(1);
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
        margin-bottom: 0;
        border-left: 0;
        padding: 40px 0;
        opacity: 0;
        transform: translate3d(0, 100%, 0);
        transition: all 600ms ease;
        z-index: 10;

        &.in {
          opacity: 1;
          -ms-transform: none;
          transform: none;
        }

        .box-wrapper {
          .box-inner {
            h2 {
              font-size: 43px;
              line-height: 50px;


            span {
              font-size: 43px;
              line-height: 50px;
            }

            br {
              display: none;
            }
            }
          }
        }
      }
    }
  }
}
