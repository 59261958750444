// Subpage styles
#chairmans-story-overlay {
  .container {
    max-width: 1400px !important;
  }

  header {
    width: 100%;
    h1 {
      opacity: 1;
      transform: none;
      color: $dark-blue-new;
      &:after {
        background: none;
      }
    }
  }

  .article {
    section {
      ul.boardmembers {
        li {
          @include columns(4);
          margin-top: 20px;
          padding: 0px 10px;
          text-align: center;
          @media only screen and (max-width: $tablet-small) {
            @include columns-change(2);
          }
          @media only screen and (max-width: $mobile) {
            @include columns-change(1);
          }
          img {
            max-width: 160px;
            height: auto;
            margin: 8px auto;
          }
          .__boardtitle {
            font-weight: 400;
          }
          .__role {
            @include paragraph-small;
            display: inline-block;
            margin: 0px auto;
          }
          .__name {
          }
          .__company {
            font-style: italic;
          }
        }
      }
    }
  }
}

// Section styles
#chairmans-story {
  position: relative;
  background: #ececea;
  .fp-tableCell {
    vertical-align: bottom;
    padding-bottom: 40px;
  }
  .container {
    max-width: inherit;

    .page-title {
      @include page-title;
      z-index: 1;
      color: $dark-blue-new;
    }
    .content-area {
      padding: 0 10px;
      width: 49%;
      display: inline-block;
      text-align: right;
      vertical-align: bottom;
      position: relative;
      z-index: 1;
      padding-bottom: 80px;

      &.first-area {
        padding-right: 43px;
        h1,
        h2,
        p {
          transform: translate3d(-200px, 0, 0);
          &.in {
            opacity: 1;
            transform: none;
          }
        }
        .links {
          // display: inline-block;
          // margin-top: 0;
          &:hover {
            // display: inline-block;
            // margin-top: 0;
            //transform: translateX(14px);
          }
        }
      }
      &.second-area {
        text-align: left;
        //padding-left: 130px;
        padding-left: 63px;
        h1,
        h2,
        p {
          transform: translate3d(200px, 0, 0);
          color: $dark-blue-new;
          &.in {
            opacity: 1;
            transform: none;
          }
        }
      }
      h1,
      h2 {
        @include headline-underline;
        color: $white;
        margin-bottom: 43px;
        font-size: 36px;
        line-height: 48px;
        font-family: $font-vdk-bold;
        transform: translate3d(0, 100px, 0);
        transition: opacity 1s ease, transform 1s ease;
        opacity: 0;
        @media only screen and (max-width: $mobile) {
          color: $white;
        }

        span {
          color: $red;
          @media only screen and (max-width: $mobile) {
            color: $white;
          }
        }

        &:after {
          display: none;
        }
      }
      p {
        color: $white;
        font-size: 26px;
        margin-bottom: 10px;
        font-weight: 600;
        transform: translate3d(0, 100px, 0);
        transition: opacity 1s ease, transform 1s ease;
        opacity: 0;
        @media only screen and (max-width: $mobile) {
          color: $white;
        }
      }
      .links {
        color: $white;
        margin-top: 10px;
        display: inline-block;
        margin-top: 0;
        transform: translate3d(0, 50px, 0);
        opacity: 0;
        @media only screen and (max-width: $mobile) {
          color: $white;
        }
        &:before {
          color: $white;
          top: 5px;
        }
        &.in {
          opacity: 1;
          transform: none;
        }
      }

      &:first-child {
        //padding: 0 0 0 10%;
        text-align: left;
        @media only screen and (max-width: $mobile) {
          padding: 0px;
          margin-bottom: 60%;
        }
      }
    }
    .figures-wrap {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;

      .figure-box {
        width: 50%;
        position: relative;
        height: 100%;
        float: left;
        opacity: 0;
        transition-property: opacity, top, bottom;
        transition-duration: 1.0s;
        transition-timing-function: ease-in-out;

        &.left-figure {
          background-image: url('../images/AllanAgerholm.jpg');
          background-size: cover;
          background-position: right center;
          top: 100%;
          &.in {
            top: 0;
            opacity: 1;
          }
        }

        &.right-figure {
          background-image: url('../images/JanOlsen.jpg');
          background-size: cover;
          background-position: left center;
          top: -100%;
          &.in {
            left: 0;
            top: 0;
            opacity: 1;
          }
        }

        img {
          position: absolute;
          bottom: 0px;
          opacity: 0;
          //transition-property: opacity, left, right;
          transition-property: opacity, top, bottom;
          transition-duration: 0.8s;
          transition-timing-function: ease-in-out;
          // transition: opacity 0.8s ease-in-out, left 0.8s ease-in-out, right 0.8s ease-in-out;

          &.mobile {
            display: none;

            @media only screen and (max-width: $mobile) {
              display: block;
            }
          }

          &.desktop {
            display: block;

            @media only screen and (max-width: $mobile) {
              display: none;;
            }
          }

          &.left-figure {
            right: 30%;
            top: 100%;
            &.in {
              right: 0;
              top: 0;
            }
          }
          &.right-figure {
            left: 30%;
            top: -100%;
            &.in {
              left: 0;
              top: 0;
            }
          }
          &.in {
            opacity: 1;
            transform: none;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $tablet-small) {
  #chairmans-story {
    .container {
      .content-area {
        width: 100%;

        &.first-area {
          padding: 0 20px;
          text-align: left;
          margin-bottom: 20px;
        }
        &.second-area {
          padding: 0 20px;
          text-align: right;
          margin-bottom: 30px;
        }
      }

      .figures-wrap {
        .figure-box {
          img {
            &.left-figure {
              &.in {
                right: -30%;
              }
            }
            &.right-figure {
              &.in {
                left: -17%;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $mobile) {
  #chairmans-story {
    .container {
      height: 100%;

      .content-area {
        position: relative;
        width: 100%;
        height: 50%;

        p {
          font-size: 18px;
        }

        .area-text {
          position: absolute;
          top: 50%;
          width: 50%;
          padding-top: 50px;
          transform: translateY(-50%);
          text-align: left;
        }

        .links {
          width: 40px;
          height: 40px;
          padding: 0;
          border: 2px solid $dark-blue-new;
          border-radius: 100%;
          background: none;
          font-size: 0;
          line-height: 40px;

          &:after {
            right: 10px;
            top: 12px;
          }

          &:hover:after {
            transform: none;
          }
        }

        &.first-area {
          padding: 0;

          .area-text {
            right: 0;
          }

          .links {
            border: 2px solid $white;
            &:after {
              color: $white;
            }
          }
        }
        &.second-area {
          padding: 0;

          .links {
            &:after {
              background-image: url('../images/svg/arrow-right-solid-blue.svg');
              background-size: 100%;
            }
          }
        }

        h1,
        h2 {
          font-size: 21px;
          line-height: 28px;
          letter-spacing: -1px;
          br {
            display: none;
          }
        }
      }

      .figures-wrap {
        .figure-box {
          width: 100%;
          height: 50%;
          img {
            position: inherit;
            width: 100%;
            height: 100%;
            &.left-figure {
              &.in {
                //right: -50%;
                right: inherit;
              }
            }
            &.right-figure {
              &.in {
                //left: -32%;
                left: inherit;
              }
            }
          }
        }
      }
    }
  }
}
