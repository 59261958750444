.video-player {
  display: none;
  width: 100%;
  height: auto;
  max-height: 230px; //Fix the space under the video
}

.video-overlay {
  position: relative;
  &:after {
    content: " ";
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    //background-color: rgba($grey-blue, 0.6);
    cursor: pointer;
    text-decoration: none;
  }

  .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    cursor: pointer;
    transition: all 300ms ease;

    span {
      display: block;
      width: 56px;
      height: 56px;
      padding: 2px 0 2px 0;
      background-color: rgba($white, 0.2);
      border: 1px solid $white;
      border-radius: 50%;
      text-align: center;
      &:before {
        margin: 0;
        color: $white;
        font-size: 44px;
        line-height: 48px;
      }
    }
  }
  &:hover {
    .play-button  {
      transform: scale(1.15);
      transition: all 300ms ease;
    }
  }
}
