#effekt-overlay {
  svg {
    max-width: 670px;
  }
}

#effekt-section {
  .article-wrapper {
    .article {
      padding-top: 0;
    }
  }
}

#effekt-section {
  background: $light-blue;

  @media only screen and (max-width: $mobile) {
    background: $light-blue;

    .container,
    header {
      height: 100%;
    }
  }

  .page-title {
    @include page-title;
    color: $dark-blue-new;
    right: 17px;
    top: 43px;
  }

  .frame-area {
    float: left;
    position: absolute;
    width: 50%;
    height: 100%;
    right: 0;
    top: 0;
    z-index: 1;
    @include columns(1, 2);

    @extend %effect;

    &.in {
     @extend %in;
    }

    .box-wrapper {
      position: relative;
      max-width: 446px;
      margin-left: 100px;

      img {
        &.desktop {
          display: block;
          margin: 0 auto 80px auto;

          @media only screen and (max-width: 1024px){
            height: 170px;
            margin-bottom: 30px;
          }

          @media only screen and (min-height: 709px) and (max-height: 850px){
            height: 150px;
            margin-bottom: 30px;
          }

          @media only screen and (max-height: 710px) and (min-width: 1025px) {
            height: 130px;
            margin-bottom: 30px;
          }
        }
        &.mobile {
          display: none;
        }
      }

      @media only screen and (max-width: 1024px){
        height: 95% !important;
        margin-left: 0;
      }

      @media only screen and (max-width: $mobile) {
        margin-left: 0;
        max-width: 100%;

        img {
          &.desktop {
            display: none;
          }
          &.mobile {
            display: block;
            margin: -26px auto 0 auto;
          }
        }
      }

      .shadow {
        box-shadow: inset 0 0 0 11px $white;
      }

      .box-inner {
        padding: 70px 40px 20px 40px;
        color: $dark-blue-new;
        background: none;
        border: 0;
        box-sizing: border-box;
        // Using inset box-shadow instead of border for sizing simplicity
        box-shadow: inset 0 0 0 11px $light-blue;

        // Required, since we're setting absolute on pseudo-elements
        position: relative;
        vertical-align: middle;

        .box-content {
          @extend %effect;

          &.in {
           @extend %in;
          }
        }

        &::before,
        &::after {
          box-sizing: inherit;
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
        }

        &.draw {
            transition: color 0.25s;

          &::before,
          &::after {
            // Set border to invisible, so we don't see a 4px border on a 0x0 element before the transition starts
            border: 11px solid transparent;
            width: 0;
            height: 0;
          }

          // This covers the top & right borders (expands right, then down)
          &::before {
            top: 0;
            left: 0;
          }

          // And this the bottom & left borders (expands left, then up)
          &::after {
            bottom: 0;
            right: 0;
          }

          // Hover styles
          &.hover::before,
          &.hover::after {
            width: 100%;
            height: 100%;
          }

          &.hover::before {
            border-top-color: $white; // Make borders visible
            border-right-color: $white;
            transition:
              width 0.25s ease-out, // Width expands first
              height 0.25s ease-out 0.25s; // And then height
          }

          &.hover::after {
            border-bottom-color: $white; // Make borders visible
            border-left-color: $white;
            transition:
              border-color 0s ease-out 0.5s, // Wait for ::before to finish before showing border
              width 0.25s ease-out 0.5s, // And then exanding width
              height 0.25s ease-out 0.75s; // And finally height
          }
        }

        .key-number {
          margin-bottom: 90px;

          span {
            float: left;
            margin-bottom: $space;
            font-family: $font-vdk-extraBold;
            font-weight: 800;
            font-size: 82px;
            line-height: 69px;
            // font-size: 44px;
            // line-height: 82px;

            &.num {
              font-size: 82px;
              line-height: 69px;
            }
          }

          p {
            padding-top: $space * 1.5;
            font-size: 22px;
            line-height: 26px;
            font-weight: 400;
            font-family: $font-vdk-semiBold;
            clear: both;
          }

          @media only screen and (max-width: 1024px){
            span {
              font-size: 62px;
              line-height: 62px;

              &.num {
                font-size: 62px;
                line-height: 62px;
              }
            }

            p {
              font-size: 20px;
              line-height: 24px;
            }
          }

          @media only screen and (min-height: 709px) and (max-height: 900px){
            span {
              font-size: 62px;
              line-height: 62px;

              &.num {
                font-size: 62px;
                line-height: 62px;
              }
            }

            p {
              font-size: 20px;
              line-height: 24px;
            }
          }

          @media only screen and (max-height: 710px) and (min-width: 1025px) {
            margin-bottom: 30px;

            span {
              font-size: 62px;
              line-height: 69px;

              &.num {
                font-size: 62px;
                line-height: 69px;
              }
            }

            p {
              font-size: 18px;
              line-height: 22px;
            }
          }

            @media only screen and (max-width: $mobile) {
              margin-bottom: 20px;

              span {
                font-size: 37px;
                line-height: 69px;

                &.num {
                  font-size: 37px;
                  line-height: 69px;
                }
              }
            }
        }

        .text {
          font-family: $font-vdk-medium;
          font-weight: 400;
          font-size: 16px;
          line-height: 25px;
          letter-spacing: 0.4px;
        }

        @media only screen and (max-height: 710px) and (min-width: 1025px) {
          padding: 40px 40px 20px 40px;

          .text {
            font-size: 12px;
            line-height: 21px;
          }
        }

        @media only screen and (max-width: $mobile) {
          padding: 20px 25px 20px 35px;
        }
      }
      .small-text {
        font-family: $font-vdk-medium;
        font-weight: 400;
        font-size: 12px;
        line-height: 28px;
        letter-spacing: 0.2px;
        color: $dark-blue-new;
        text-align: center;
        @extend %effect;

        &.in {
         @extend %in;
        }
      }
    }

    @media only screen and (max-width: $mobile) {
      @include columns-change(1);
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
      margin-bottom: 0;
      border-left: 0;
      padding: 40px 0;
      opacity: 0;
      transform: translate3d(0, 100%, 0);
      transition: all 600ms ease;
      background: $light-blue;

      &.in {
        opacity: 1;
        -ms-transform: none;
        transform: none;
      }
    }

    .box-wrapper {
      height: 100%;

      .box-inner {
        position: absolute;
        left: $space * 1.5;
        right: $space * 1.5;
        top: $space * 7;
        bottom: $space * 6;
        background-color: $light-blue;

        @media only screen and (max-width: $mobile) {
          bottom: -10px;
        }
      }

      .small-text {
        position: absolute;
        bottom: 20px;
        left: 15px;
        @media only screen and (max-width: $mobile) {
          bottom: -50px;
        }
      }
    }
}
  .container.section-container {
    height: 100%;

    header {
      @include columns(1, 2);
      margin-top: 10vh;

      h1 {
        @include headline-title;
        @extend %effect;
        color: $dark-blue-new;
        &.in {
          @extend %in;
        }

        @media only screen and (max-width: $mobile) {
          br {
            display: none;
          }
        }
      }
      p {
        @include paragraph;
        @extend %effect;
        margin: 0 0 20px 0;
        padding-right: 87px;

        @media only screen and (max-width: $mobile) {
          font-size: 14px;
          line-height: 20px;
          span {
            display: none;
          }
        }

        @media only screen and (max-width: $mobile-small) {
          font-size: 14px;
          line-height: 20px;
        }
        &.in {
          @extend %in;
        }
      }

      .subtext-link-box {
        margin-top: $space * 8;
        @extend %effect;
        &.in {
          @extend %in;
        }
        @media only screen and (max-width: 1024px){
          margin-top: 0;
        }

        @media only screen and (min-height: 650px) and (max-height: 950px) {
          margin-top: 0;
        }

        @media only screen and (max-height: 710px) and (min-width: 1025px) {
          margin-top: 0;
        }
      }

      @media only screen and (max-width: 1024px) {
        margin-top: 12.5vh;

        h1 {
          font-size: 36px;
          line-height: 41px;
          margin: 0 0 50px 0;
          &:after {
            bottom: -27px;
          }
        }
        p {
          font-size: 15px;
          line-height: 22px;
          margin: 0 0 10px 0;
        }
      }

      @media only screen and (max-height: 710px) and (min-width: 1025px) {
        margin-top: 12.5vh;

        h1 {
          font-size: 36px;
          line-height: 41px;
          margin: 0 0 50px 0;
          &:after {
            bottom: -27px;
          }
        }
        p {
          font-size: 15px;
          line-height: 22px;
          margin: 0 0 10px 0;
        }
      }

      @media only screen and (max-width: $mobile) {
        @include columns-change(1);
        margin: 0;

        h1 {
          padding: 0;
          margin: 41px 0 29px 0;

          span {
            @include columns-change(1);
          }
        }

        p {
          padding: 0;
          font-size: 13px;
          line-height: 20px;
          margin-bottom: 0;
        }
      }
    }
  }

  .slideshow {
    @include columns(1, 2);
    position: absolute;
    width: 50%;
    right: 0;
    top: 0;
    padding: 0;
    z-index: 1;

    .fp-tableCell {
      vertical-align: bottom;
      padding-bottom: 95px;
      position: relative;
    }

    .slide {
      background-position: top left;
      background-size: cover;
      background-repeat: no-repeat;
      &:after {
        content: "";
        position: absolute;
        top: auto;
        right: 0px;
        bottom: 0px;
        height: 2px;
        left: 0px;
        transition: all 600ms ease;
        background-color: rgba($black, 0);
        z-index: 101;
      }
      &.in {
        &:after {
          height: 100%;
        }
      }

      @media only screen and (max-width: $mobile) {
        background: $dark-blue-new;
      }

      .slide-main-image {
        position: absolute;
        top: 10%;
        left: 50%;
        width: 450px;
        max-width: 70%;
        opacity: 0;
        transform: translate(-53%, 50px);
        transition: transform 0.5s ease;
        &.in {
          opacity: 1;
          transform: translate(-53%, 0px);
        }

        @media only screen and (max-height: 810px) and (min-width: 1025px) {
          // width: 56vh;
          height: 43vh;
          top: 10vh;
        }
      }

      .intro {
        @extend %effect;
        padding: 0 20px;
        width: 80%;
        max-width: 490px;
        margin: 0 auto;
        position: relative;
        z-index: 102;
        &.in {
          @extend %in;
        }

        h3 {
          font-weight: 900;
          font-size: 17px;
          margin: 0 0 3px 0;
          text-transform: uppercase;
        }

        p {
          font-size: 17px;
          line-height: 25px;
        }

        @media only screen and (max-height: 640px) and (min-width: 1025px) {
          p {
            font-size: 15px;
            line-height: 21px;
          }
        }

        @media only screen and (max-width: $mobile) {
          width: 100%;
          max-width: none;
          margin-bottom: 20px;

          p {
            font-size: 13px;
            line-height: 16px;
          }
        }
      }
    }
    .fp-controlArrow {
      left: 20px;
      border: 0;
      margin: 0;

      &:hover {
        &:before {
          color: $red;
        }
      }
      &:before {
        font-family: "icons";
        font-style: normal;
        speak: none;
        display: inline-block;
        text-decoration: inherit;
        text-align: center;
        font-variant: normal;
        text-transform: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 23px;
        font-weight: 100;
        width: 50px;
        line-height: 48px;
        height: 50px;
        border: 2px solid #fff;
        border-radius: 100%;
        text-indent: -3px;
        opacity: 0;
        transition: opacity 0.3s, transform 0.6s ease, color 0.3s ease;
      }
      &.fp-prev {
        &:before {
          transform: translateY(200%);
        }
      }
      &.fp-next {
        margin-top: -65px;

        &:before {
          text-indent: 3px;
          transform: translateY(-200%);
        }
      }
      &.in {
        &:before {
          opacity: 1;
          transform: translateY(0%);
        }
      }

      @media only screen and (max-width: $mobile) {
        right: 70px;
        left: auto;

        &:hover {
          &:before {
            color: $white;
          }
        }
        &.fp-prev {
          margin-top: -50px;
        }
        &.fp-next {
          margin-top: -115px;
        }
      }
    }

    @media only screen and (max-width: $mobile) {
      @include columns-change(1);
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
      margin-bottom: 0;
      border-left: 0;
      padding: 40px 0;
      opacity: 0;
      transform: translate3d(0, 100%, 0);
      transition: all 600ms ease;

      &.in {
        opacity: 1;
        -ms-transform: none;
        transform: none;
      }
    }
  }

  .fp-slidesNav { display: none;
    &.bottom {
      margin: 0 !important;
      bottom: 60px;
      width: 50%;

      ul {
        margin: 0 auto;
        display: table;

        li {
          float: left;
          width: 70px;
          height: 4px;
          margin: 0 0 0 5px;

          &:first-child {
            margin-left: 0;
          }

          a {
            &.active,
            &:hover {
              span {
                background-color: $red;
              }
            }
            span {
              display: block;
              height: 100%;
              width: 100%;
              margin: 0;
              position: static;
              border-radius: 0;
              background-color: #fff;
              transition: background-color 0.3s ease;
            }
          }
        }
      }
      @media only screen and (max-width: $mobile) {

        width: 100%;
        left: 0;
        padding: 0 20px;
        opacity: 0;
        transform: translate3d(0, 50px, 0);
        transform: all 0.6s ease;
        &.in {
          opacity: 1;
          transform: none;
        }

        ul {
          width: 100%;
          li {
            width: 18%;
            height: 4px;
            margin: 0 0 0 2.5%;
            a,
            a span {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
}
