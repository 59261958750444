// Enable border-box for all elements
*,
*:before,
*:after {
  box-sizing: border-box;
}

// Extent %clearfix where needed to fix height/margins on wrappers with floated elements.

%clearfix {
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}

// Include container on any container element with the max width layout.

@mixin container {
  @extend %clearfix;
  width: 100%;
  margin: 0px auto;
  max-width: $grid-max-width;
  padding: 0 $grid-side-margin;
  @media only screen and (max-width: $tablet) {
    padding: 0 $grid-tablet-side-margin;
  }
  @media only screen and (max-width: 767px) {
    padding: 0 $grid-mobile-side-margin;
  }
}

// Include columns to calculates the width of a column and set generic column styles. Use this to set initial grid columns.

@mixin columns($span, $total: 1) {
  float: left;
  padding: 0 $grid-gutter;
  @if $total <= 1 {
    width: 100% / $span;
  } @else {
    width: 100% / $total * $span;
  }
  @media only screen and (max-width: $tablet) {
    padding: 0 $grid-tablet-gutter;
  }
  @media only screen and (max-width: 767px) {
    padding: 0 $grid-mobile-gutter;
  }
}

// Defining columns with no paddings or margins. Use this to set inner grid columns.

@mixin columns-pure($span, $total: 1) {
  float: left;
  @if $total <= 1 {
    width: 100% / $span;
  } @else {
    width: 100% / $total * $span;
  }
}

// Include columns-change to calculates the width of a column. Use this to modify width for columns in different media queries.

@mixin columns-change($span, $total: 1) {
  @if $total <= 1 {
    width: 100% / $span;
  } @else {
    width: 100% / $total * $span;
  }
}

.container {
  @include container;
}
