// Fonts
$font: "Open Sans", helvetica, sans-serif;
$font-Monserat: 'Montserrat', sans-serif;
$font-vdk-outlineExtraBold: 'VisitDenmark Outline';
$font-vdk-regular: 'VisitDenmark Regular';
$font-vdk-medium: 'VisitDenmark Medium';
$font-vdk-semiBold: 'VisitDenmark SemiBold';
$font-vdk-bold: 'VisitDenmark Bold';
$font-vdk-extraBold: 'VisitDenmark ExtraBold';

// Colors
$white: #ffffff;
$black: #000000;
$dark-grey: #474847;
$grey: #6d6e6d;
$light-grey: #bfc0bf;
$medium-grey: #eef3f5;
$bkg-grey: #f7f9fb;
$extra-grey: #f7f9fb;

$dark-red: #9d1920;
//$red: #cb1b24;
//$red: #ca1b24;
$red: #df041f;
$medium-red: #c03e41;

$orange: #d64b25;
$yellow: #e58d2c;
$pale-yellow: #fee374;

$bordeaux: #3d0b1d;
$purple: #6c2368;
$pink: #cb126a;

$dark-blue-new: #082747;
$light-blue: #bde7ff;
$light-blue-text: #008ed3;
$dark-blue: #355767;
$bright-blue: #00a5d5;
$pale-blue: #a5c2c0;
$blue: #21404f;
$grey-blue: #21404f;

$green: #00ae42;
$blue-green: #00c897;
$pale-dark-green: #406049;
$grass-green: #72b039;
$light-green: #b7cb43;

$dark-earth: #37302c;
$earth: #7d7463;
$light-earth: #d2cbb5;
$medium-earth: #f0f2f4;

// Grid

//$grid-max-width: 1200px;
//$grid-side-margin: 80px;
$grid-max-width: 1400px;
$grid-side-margin: 60px;
$grid-tablet-side-margin: 10px;
$grid-mobile-side-margin: 20px;
$grid-gutter: 10px;
$space: 10px;
$grid-tablet-gutter: 10px;
$grid-mobile-gutter: 5px;

// Breakpoints

$mobile-small: 320px;
//$mobile: 480px;
$mobile: 767px;
$tablet-small: 768px;
$tablet: 1024px;
$desktop-small: 1200px;
