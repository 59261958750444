.section-bkg {
  background: $bkg-grey;
}

.subpage-layout {
  background-color: $medium-grey;
  padding-top: 100px;
  @media only screen and (max-width: 767px) {
    padding-top: 80px;
  }
  .topbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    z-index: 10;
    background-color: $white;
    text-align: left;
    @media only screen and (max-width: 767px) {
      height: 80px;
    }
    .back-button {
      @include button($pink, 5);
      margin-top: 25px;
      font-weight: 600;
      @media only screen and (max-width: 767px) {
        margin-top: 15px;
      }
      img {
        height: 18px;
        width: 18px;
        vertical-align: -4px;
        margin-right: 10px;
      }
    }
  }
}

.article {
  padding-top: 70px;
  padding-top: 8vh;
  padding-bottom: 40px;
  padding-bottom: 4vh;
  text-align: left;
  color: $dark-blue-new;
  @media only screen and (max-width: 767px) {
    padding-top: 40px;
  }
  header {
    @include columns(1);
    width: 100%;
    margin-bottom: 15px;
    margin-bottom: 1vh;
    h1 {
      //@include headline-underline;
      font-family: $font-vdk-bold;
      font-size: 60px;
      font-weight: 400;
      line-height: 70px;
      margin: 0 0 50px 0;
      text-transform: inherit;
      color: $dark-blue-new;
      &:after {
        bottom: -27px;
        width: 80px;
        height: 3px;
      }
    }
    &.info {
      h1 {
        @include headline-underline;
        @include headline-not-underline;
        margin: 0 0 30px 0;
        text-transform: inherit;
      }
    }
    .intro {
      @include paragraph-xtra-medium;
      letter-spacing: 0.5px;
    }
    .info {
      position: relative;
      padding: 5px 10px;
      margin: 0 0 25px 0;
      border-top: 1px solid $dark-blue-new;
      border-bottom: 1px solid $dark-blue-new;
      img {
        max-width: 100px;
        display: block;
      }
      .info-content {
        @extend %centered;
        padding: 0 0 0 120px;
        .__boardtitle,
        .__name {
          @include paragraph-medium;
          font-size: 17px;
        }
        .__name {
          font-weight: 600;
        }
      }
    }

    p {
      @include paragraph-large;
      font-weight: 300;
    }
  }
  section {
    @include columns(1);
    margin-bottom: 80px;
    margin-bottom: 10vh;
    h2 {
      @include paragraph-large;
    }
    p {
      @include paragraph;
      margin-top: 20px;
    }

    p > img {
      max-width: 670px;
    }

    .video {
      margin: 20px 0px 0px 0px;
      width: 100%;
      max-width: 100%;;
      height: auto;
      position: relative;
      img {
        display: block;
        width: 100%;
      }
    }
    .image-wrapper {
      background-color: lighten($black, 18);
      padding: 20px;
      margin-top: 20px;
      margin-bottom: 20px;
      @media only screen and (max-width: 767px) {
        margin-left: -20px;
        margin-right: -20px;
      }
      img {
        width: 100%;
        height: auto;
      }
      .image-source {
        margin-top: 30px;
        color: $light-grey;
        font-style: italic;
      }
    }
  }
}
