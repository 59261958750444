#fp-nav {
  @media only screen and (max-width: 767px) {
    display: none !important;
  }
  .touch &.right {
    right: 37px;
  }
  ul {
    li {
      display: block;
      position: relative;
      padding: 5px 0px;
      @media only screen and (max-width: 767px) {
        display: inline-block;
        padding: 0px 0px;
        margin: 4px;
      }
      a {
        outline: none;
        &.active span {
          //height: 20px;
          border-radius: 6px;
          width: 8px;
          height: 8px;
          //border: 2px solid $white;
          //background-color: $white;
          //background: $red;
          background: #ff1b27;
          margin: -6px 0 0 -2px;
          //margin: -13px 0 0 -2px;
          @media only screen and (max-width: 767px) {
            margin: -2px 0 0 -13px;
            width: 20px;
            height: 8px;
          }
        }
        span {
          display: block;
          border-radius: 6px;
          position: relative;
          z-index: 1;
          height: 8px;
          width: 8px;
          background: $white;
          margin: -6px 0 0 -2px;
          box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.1);
          transition: all 300ms ease-in-out;
          @media only screen and (max-width: 767px) {
            margin: -2px 0 0 -6px;
          }
        }
      }
      .fp-tooltip {
        position: absolute;
        top: 50%;
        margin-top: -10px;
        color: #fff;
        font-size: 12px;
        font-weight: 600;
        line-height: 14px;
        font-family: $font;
        white-space: nowrap;
        max-width: 220px;
        overflow: visible;
        display: block;
        opacity: 0;
        width: 0;
        cursor: pointer;
        text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
        @media only screen and (max-width: 767px) {
          top: -20px;
          opacity: 0;
          margin-top: 0px;
          width: auto;
          margin: 0px auto;
        }
        &.right {
          right: -300px;
          @media only screen and (max-width: 767px) {
            right: auto;
          }
        }
      }
      &:hover {
        a {
          span {
            width: 8px;
            height: 8px;
            margin: -6px 0 0 -2px;
            background-color: #ff1b27;
          }

          &.active {
            span {
              width: 8px;
              height: 20px;
              margin: -13px 0 0 -2px;
              background-color: #ff1b27;
              @media only screen and (max-width: 767px) {
                width: 20px;
                height: 8px;
                margin: -2px 0 0 -13px;
              }
            }
          }
        }
        .fp-tooltip {
          transition: opacity 800ms ease;
          width: auto;
          opacity: 1;

          &.right {
            right: 20px;
          }
        }
      }
    }
  }
}
