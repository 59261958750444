#footer {
  background-color: $black;
  background-image: url('../images/backgrounds/bkg-section-12.jpg');
  background-size: cover;
  background-position: center;

  .container {
    padding-bottom: 90px;
    text-align: center;
  }

  .page-title {
    @include page-title;
    color: $white;
    right: -27px;
    top: 100px;
  }

  h1 {
  //  margin: 6vh 0 0px 0;
    margin: 18vh 0 0px 0;
    font-family: $font-vdk-outlineExtraBold;
    font-weight: 400;
    font-size: 128px;
    line-height: 0.85em;
    text-transform: uppercase;
    color: $extra-grey;
    @extend %effect;
    &.in {
      @extend %in;
    }

    span.desktop > span,
    span.mobile > span {
      display: block;
      font-family: $font-vdk-bold;
      font-size: 75px;
      text-transform: none;
    }

    span.mobile {
      font-size: 60px;
      line-height: 60px;

      > span {
        font-size: 44px;
      }
    }
  }

  h2 {
    font-size: 28px;
    font-weight: 400;
    letter-spacing: -1px;
    max-width: 680px;
    line-height: 1.2em;
    margin: 0 auto 2vh auto;
    font-family: $font-vdk-bold;
    @extend %effect;
    &.in {
      @extend %in;
    }
  }

  h3 {
    font-family: $font-vdk-medium;
    font-size: 16px;
    @extend %effect;
    &.in {
      @extend %in;
    }
  }

  .download-box, h1 {
    span {
      &.desktop {
        display: block;
      }
      &.mobile {
        display: none;
      }
    }
  }

  .bottom-wrap {
    position: absolute;
    left: 0;
    //bottom: 70px;
    bottom: 14%;
    width: 100%;
    margin: 0;
    @extend %effect;
    &.in {
      @extend %in;
    }

    .inner {
      display: table;
      width: 100%;
      max-width: 650px;
      margin: 0 auto;

      img {
        margin: 0 auto;
      }
    }

    .download-box {
      .links {
        margin-bottom: 64px;
      }
    }
    .social-box {
      ul {
        li {
          display: inline-block;
          margin: 0 0 0 8px;
          &:first-child {
            margin-left: 0;
          }
          a {
            display: block;
            width: 38px;
            height: 38px;
            font-size: 0;
            color: #fff;
            text-decoration: none;

            &:before {
              line-height: 38px;
              font-size: 38px;
              margin: 0;
              transition: color 0.6s ease;
            }
            &:hover {
              color: $red;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-height: 785px) and (min-width: 1024px) {
    h1 {
      //margin-top: 4vh;
      margin-top: 10vh;
      font-size: 55px;
      span {
        font-size: 45px;
      }
    }
    h2 {
      font-size: 29px;
      margin-bottom: 25px;
      &:after {
        margin-top: 23px;
      }
    }
    .bottom-wrap {
      .dl-headline,
      .social-headline {
        margin-bottom: 25px;
      }
    }
  }

  @media only screen and (max-height: 685px) and (min-width: 1024px) {
    h1 {
      font-size: 75px;
      line-height: 0.75em;
      span {
        font-size: 45px;
      }
    }
    h2 {
      font-size: 25px;
    }
    .bottom-wrap {
      .dl-headline,
      .social-headline {
        margin-bottom: 15px;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .container {
      display: flex;
      justify-content: center;
      height: 80%;
      align-items: center;
    }
    h1 {
      text-transform: none;
      margin: 0;
      span.desktop {
        font-size: 54px;
      }
      span.moobile {
        font-size: 55px;
        letter-spacing: -1px;
        padding: 7px 0 0 0;
        span {
          font-size: 35px;
        }
      }
    }
    h2, h3 {
      display: none;
    }
    .download-box, h1 {
      span {
        &.desktop {
          display: none;
        }
        &.mobile {
          display: block;
        }
      }
    }
    .bottom-wrap {
      .dl-headline,
      .social-headline {
        font-weight: 700;
        font-size: 16px;
        text-align: center;
        margin: 0 auto 15px auto;
      }
      .download-box {
        width: 100%;
        .button {
          margin: 0 auto;
          display: table;
        }
      }
      .social-box {
        width: 100%;
        margin: 25px 0 20px 0;
        ul {
          margin: 0 auto;
          display: table;
        }
      }
    }
  }
}
