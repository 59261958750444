/* Standard syntax */
#intro {
.box {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  //perspective: 400px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  //transform-style: preserve-3d;
  //transition: perspective 4s;
  //transition-timing-function: linear;
}

.fore-box {
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg-box {
  z-index: 5;
  width: 100%;
}

.frame {
  height: 100%;
  width: 100%;
  position: relative;
}

.img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  transition: all 7s;
  transition-timing-function: ease;

  &.fore {
    z-index: 10;
    background-image: url("/images/backgrounds/fore.png");
    //background-position: center 360px;
    background-position: center bottom;
    background-repeat: no-repeat;
    top: 45%;
    transform: scale(1);
    background-size: cover;
    @media only screen and (max-height: 710px) and (min-width: 1025px) {
        background-position: center top;
        top: 45%;
    }
  }

  &.bg {
    background-image: url("/images/backgrounds/bg.png");
    background-position: center  center;
    background-repeat: repeat-x;
    left: 0;
    background-size: cover;
  }

  @media only screen and (max-width: $mobile) {
    &.fore {
      background-image: url("/images/backgrounds/section-1-mobile.png");
      background-position: center top;
      transform: none;
    }

    &.bg {
      display: none;
    }
  }
}

.box.on {
  .fore {;
    background-position: center bottom;
    top: 34%;
    transform: scale(1.1);
  }

  &.bg-box {
    width: 125%;
    transition: width 6s;
  }

  .bg {
    background-repeat: repeat-x;
    background-position: center center;
    }

    @media only screen and (max-height: 710px) and (min-width: 1025px) {
      .fore {
        background-position: center top;
        top: 35%;
      }
    }

    @media only screen and (max-width: $mobile) {
      .fore {
        background-image: url("/images/backgrounds/section-1-mobile.png");
        background-position: center top;
        transform: none;
        transition: none;
        top: 0;
      }

      .bg {
        display: none;
      }
    }
}

  video {
    transition: top 0.3s ease;
  }

  .frame-inner {
    position: relative;
    z-index: 10;

    .logo {
      position: fixed;
      bottom: $space * 5;
      right: 100px;
      margin: $space auto $space * 3 auto;
      max-width: 100%;
      display: block;
      @extend %effect;

      &.in {
        @extend %in;
      }

      @media only screen and (max-width: $mobile) {
        position: inherit;
        bottom: inherit;
        right: inherit;
        margin-top: $space * 3;
      }
    }
  }

  p {
    position: absolute;
    align-items:center;
    font-family: $font-vdk-outlineExtraBold;
    color: $light-blue;
    font-weight: 600;
    transform: translateY(160px);
   @extend %effect;

    &.in {
      @extend %in;
      transition: all 6s;
      transition-timing-function: ease;
      transform: translateY(-70px);
    }

    strong {
      font-size: 17vw;
      line-height: 1;
      &.desktop {
        display: inline-block;
      }

      &.mobile {
        display: none;
      }
    }

    @media only screen and (max-height: 710px) and (min-width: 1025px) {
      &.in {
        transform: none;
      }
      strong {
        font-size: 16vw;
      }
    }

    @media only screen and (max-width: $mobile) {
      left: 0;
      top: 17%;
      z-index: 10;

      &.in {
        transform: translateZ(0);
      }

      strong {
        &.desktop {
          display: none;
        }

        &.mobile {
          margin-left: $space * 2;
          font-size: 32vw;
          line-height: 130px;
          display: block;
        }
      }
    }
  }

  .container {
    position: relative;
    z-index: 5;
    height: 100%;
    text-align: center;

    .container-inner {
      position: absolute;
      text-align: center;
      top: 15%;
      left: 0;
      transform: translate3d(0px, -15%, 0px);
      width: 100%;
      margin: 0 auto;
      @media only screen and (max-width: $mobile) {
        top: 65%;
        transform: translateY(-65%);
      }

      h1 {
        display: inline-block;
        font-family: $font-vdk-extraBold;
        font-size: 88px;
        line-height: 133px;
        font-weight: 400;
        margin: 0 auto 15px auto;
        padding: 10px 0px;
        //@extend %effect;
        color: $white;
        opacity: 0;
        transition: all 3s ease;
        transform: translate3d(0, 20px ,0);

        @media only screen and (max-width: $mobile) {
          margin-bottom: 5px;
          font-size: 43px;
          letter-spacing: -2px;
        }

        &.in {
        @extend %in;

        }
      }
    }

    a {
      text-decoration: none;
      position: absolute;
      left: 50%;
      bottom: 40px;
      width: 50px;
      height: 50px;
      margin-top: 60px;
      display: inline-block;
      color: $white;
      transition: opacity 600ms ease, bottom 300ms ease;
      opacity: 0;
      @extend %effect;

      &.in {
        @extend %in;
        transform: translate3d(-50%, 0, 0);
      }
      &:before {
        @include icons-fonts;
        content: "\ea3e";
        font-size: 24px;
        font-weight: 600;
        width: 50px;
        line-height: 48px;
        height: 50px;
        text-indent: -1px;
        transition: color 300ms ease;;
      }
      &:hover {
        //bottom: 95px;
        bottom: 45px;

        &:before {
          color: #e30613;
        }
      }
      @media only screen and (max-width: 767px) {
        bottom: 18%;
        &:hover {
          bottom: 20%;
        }
      }

      @media only screen and (max-height: 560px) and (min-width: 1025px) {
        bottom: 13vh;

        &:hover {
          bottom: 12vh;
        }
      }
    }
  }
}
