/* SASS extends */

%clearfix {
  &:before,
  &:after {
    display: table;
    content: " ";
  }
  &:after {
    clear: both;
  }
}

%centered {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

%effect {
  opacity: 0;
  transform: translate3d(0, 50px, 0);
  transition: all 600ms ease;
}

%in {
  opacity: 1;
  transform: none;
}
