#cooperation {
  background-color: $bkg-grey;

  @media only screen and (max-width: $mobile) {
    background-image: url("/images/svg/borders.svg");
    background-repeat: no-repeat;
    background-size: 90% auto;
    background-position: right 70%;
  }

  .page-title {
    @include page-title;
    color: $dark-blue-new;
    right: -32px;
    top: 97px;
  }

  .container {
    header {
      @include columns(1, 2);
      margin-top: 15vh;

      h1 {
        position: relative;
        z-index: 2;
        @include headline-title;
        @extend %effect;
        color: $dark-blue-new;
        &.in {
          @extend %in;
        }
      }
      p {
        position: relative;
        z-index: 2;
        @include paragraph;
        margin: 0 0 20px 0;
        padding-right: 87px;
        @extend %effect;

        &.in {
          @extend %in;
        }
      }
      .subtext-link-box {
        @extend %effect;
        &.in {
          @extend %in;
        }
      }

      @media only screen and (max-height: 710px) and (min-width: 1025px) {
        margin-top: 12.5vh;

        h1 {
          font-size: 36px;
          line-height: 41px;
          margin: 0 0 50px 0;

          br {
            display: none;
          }
        }

        p {
          margin: 0 0 10px 0;
        }
      }

      @media only screen and (max-width: $mobile) {
        @include columns-change(1);
        margin: 0;

        h1 {
          padding: 0;
          margin: 41px 0 29px 0;

          span {
            @include columns-change(1);
          }
        }

        p {
          padding: 0;
          margin-bottom: 0;
        }
      }
    }
  }

  .slideshow {
    @include columns(1, 2);
    position: absolute;
    width: 50%;
    right: -125px;
    top: 60px;
    bottom: 60px;
    padding: 0;
    z-index: 1;

    img {
      &.desktop {
        height: 100px;
        display: block;
        margin: 0 auto 40px auto;
      }
      &.mobile {
        display: none;
      }

      @media only screen and (min-height: 709px) and (max-height: 950px) {
        &.desktop {
          margin-bottom: 25px;
        }
      }

      @media only screen and (max-height: 710px) and (min-width: 1025px)  {
        &.desktop {
          height: 80px;
          margin: 0 auto 20px auto;
        }
      }
    }

    @media only screen and (max-width: $mobile) {
      img {
        &.desktop {
          display: none;
        }
        &.mobile {
          display: block;
          //margin: -75px auto 0 auto;
          margin: -115px auto 0 auto;
          width: 95px;
          height: 85px;

          &:nth-child(1),
          &:nth-child(3) {
            margin: -105px auto 0 auto;
          }
        }
      }
      .slide {
        &:nth-child(1),
        &:nth-child(3) {
          img.mobile {
            margin: -107px auto 0 auto;
          }
        }
      }
    }

    .slideshow-wrapper {
      height: 100%;

      .box-inner {
        padding: 20px 0;
        max-width: 418px;
        height: 100%;
        border: 0;
        box-sizing: border-box;
        box-shadow: inset 0 0 0 11px transparent;
        position: relative;
        vertical-align: middle;

        &::before,
        &::after {
          box-sizing: inherit;
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
        }

        &.draw {
            transition: color 0.25s;

          &::before,
          &::after {
            // Set border to invisible, so we don't see a 4px border on a 0x0 element before the transition starts
            border: 11px solid transparent;
            width: 0;
            height: 0;
          }

          // This covers the top & right borders (expands right, then down)
          &::before {
            top: 0;
            left: 0;
          }

          // And this the bottom & left borders (expands left, then up)
          &::after {
            bottom: 0;
            right: 0;
          }

          // Hover styles
          &.hover::before,
          &.hover::after {
            width: 100%;
            height: 100%;
          }

          &.hover::before {
            border-top-color: $light-blue; // Make borders visible
            border-right-color: $light-blue;
            transition:
              width 0.25s ease-out, // Width expands first
              height 0.25s ease-out 0.25s; // And then height
          }

          &.hover::after {
            border-bottom-color: $light-blue; // Make borders visible
            border-left-color: $light-blue;
            transition:
              border-color 0s ease-out 0.5s, // Wait for ::before to finish before showing border
              width 0.25s ease-out 0.5s, // And then exanding width
              height 0.25s ease-out 0.75s; // And finally height
          }
        }
      }
    }

    .slideshow-inner {
      position: relative;
      // padding: 20px 0;
      // max-width: 418px;
      //max-height: 840px;
      height: 100%;
      //margin: 60px auto 0 auto;
    //  border: 11px solid $light-blue;
      color: $dark-blue-new;

      &:before {
        background: url("/images/svg/borders.svg") no-repeat left center;
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -539px;
        width: 539px;
        height: 100%;
      }
    }

    .fp-tableCell {
      vertical-align: top;
      padding-bottom: 105px;
    }

    .slide {
      background-position: center bottom;
      background-size: cover;
      background-repeat: no-repeat;

      &:after {
        content: "";
        position: absolute;
        top: auto;
        right: 0px;
        bottom: 0px;
        height: 2px;
        left: 0px;
        transition: all 600ms ease;
        background-color: rgba($black, 0);
        z-index: 101;
      }
      &.in {
        &:after {
          height: 100%;
        }
      }

      @media only screen and (max-width: $mobile) {
        background-size: auto 100%;
      }

      .intro {
        padding: 0 40px;
        //width: 80%;
        max-width: 490px;
        margin: 0 auto;
        position: relative;
        z-index: 102;
        @extend %effect;

        &.in {
          @extend %in;
        }

        h3 {
          margin: 0 0 40px 0;
          font-family: $font-vdk-semiBold;
          font-weight: 400;
          font-size: 22px;
          line-height: 26px;
          color: $dark-blue-new;

          @media only screen and (min-height: 709px) and (max-height: 950px) {
            margin-bottom: 25px;
          }
        }

        p {
          @include paragraph;
          // font-family: $font-vdk-regular;
          // font-weight: 400;
          font-size: 15px;
          line-height: 25px;
          color: $dark-blue-new;

          @media only screen and (min-height: 709px) and (max-height: 950px) {
            font-size: 15px;
            line-height: 1.2;
          }
        }

        @media only screen and (max-height: 710px) and (min-width: 1025px) {
          h3 {
            margin: 0 0 20px 0;
            font-size: 15px;
            line-height: 19px;
          }

          // p {
          //   font-size: 14px;
          //   line-height: 18px;
          // }
        }

        @media only screen and (max-width: $mobile) {
          width: 100%;
          max-width: none;
          margin-bottom: 20px;

          h3 {
            margin: 35px 0 20px;
            height: 28px;
            overflow: hidden;
          }

          p {
            font-size: 13px;
            line-height: 16px;
          }
        }
      }
    }
    .fp-controlArrow {
      left: 35%;
      top: inherit;
      bottom: $space * 5;
      width: 50px;
      line-height: 52px;
      height: 50px;
      border: 2px solid $red;
      border-radius: 100%;
      text-align: center;
      opacity: 0;
      transition: opacity 0.3s, transform 0.6s ease, color 0.3s ease;

      &:hover {
        &:before {
          color: $red;
        }
      }
      &:before {
        color: $red;
        @include icons-fonts;
        font-size: 23px;
        font-weight: 100;
      }
      &.fp-prev {
        &:before {
          content: "\ea40";
          transform: translateY(200%);
        }
      }
      &.fp-next {
        right: 35%;
        left: inherit;

        &:before {
          content: "\ea3c";
          text-indent: 3px;
          transform: translateY(-200%);
        }
      }
      &.in {
        //&:before {
          opacity: 1;
          transform: translateY(0%);
        //}
      }
      @media only screen and (max-height: 710px) and (min-width: 1025px) {
        display: none;
      }

      @media only screen and (max-width: $mobile) {
        right: 70px;
        left: auto;
        display: none;

        &:hover {
          &:before {
            color: $white;
          }
        }
      }
    }

    @media only screen and (max-width: $mobile) {
      @include columns-change(1);
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
      margin-bottom: 0;
      border-left: 0;
      padding: 90px 0 90px;
      opacity: 0;
      transform: translate3d(0, 100%, 0);
      transition: all 600ms ease;
      background: $bkg-grey;
      &.in {
        opacity: 1;
        -ms-transform: none;
        transform: none;
      }

      .slideshow-wrapper {
        height: 100%;
      }

      .slideshow-inner {
        margin: 0 10px;
        //max-height: 500px;
        max-height: inherit;

        &:before {
          content: none;
        }

        .fp-slides {
          overflow: visible;
        }
      }
    }
  }

  .fp-slidesNav {
    &.bottom {
      margin: 0 !important;
      bottom: 70px;
      //left: calc(50% + 100px);
      left: calc(50% + 225px);

      ul {
        margin: 0 auto;
        padding-bottom: $space * 2.5;
        display: table;
        text-align: center;

        li {
          height: 4px;
          width: 70px;
          margin: 0 0 0 5px;

          &:first-child {
            margin-left: 0;
          }

          a {
            &.active,
            &:hover {
              span {
                background-color: $red;
              }
            }
            span {
              display: block;
              height: 100%;
              width: 100%;
              margin: 0;
              position: static;
              border-radius: 0;
              background-color: #fff;
              transition: background-color 0.3s ease;
            }
          }
        }
      }

      @media only screen and (max-width: $mobile) {
        width: 100%;
        left: 0;
        bottom: 60px;
        padding: 0 20px;
        opacity: 0;
        transform: translate3d(0, 50px, 0);
        transform: all 0.6s ease;
        &.in {
          opacity: 1;
          transform: none;
        }

        ul {
          width: 100%;
          margin-left: 0;
          padding-bottom: 60px;

          li {
            width: 14.5%;
            height: 4px;
            margin: 0 0 0 2.5%;
            a,
            a span {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
  .results-see-more {
    z-index: 10;
  }
}
