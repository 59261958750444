// Desktop height Breakpoints
$breakpoint-1: 800px;
$breakpoint-2: 700px;
$breakpoint-3: 590px;

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


#results {
  background-color: $bkg-grey;
  background-image: url('../images/backgrounds/bkg-section-5.png');
  background-size: 100%;
  background-position: center bottom;
  background-repeat: no-repeat;
  position: relative;

  @media only screen and (max-width: $mobile) {
    //background-image: url('../images/backgrounds/bkg-section-5-mobile.png');
    background-image: none;
    background-size: 100%;
    background-position: center bottom;
    background-repeat: no-repeat;
    position: relative;
    .container {
      height: 100%;
    }
  }

  .page-title {
    @include page-title;
    color: $dark-blue-new;
    right: -17px;
    top: 84px;
  }

  .container.section-container {

    @media only screen and (max-width: $mobile) {
      height: 100%;
    }

    header {
      float: left;
      margin-top: 80px;
      margin-bottom: 50px;
      margin-bottom: 6vh;
      @media only screen and (max-height: $breakpoint-1) and (min-width: 1025px) {
        margin-top: 60px;
        margin-top: 9.7vh;
        margin-bottom: 20px;
        margin-bottom: 3vh;
      }
      @media only screen and (max-height: $breakpoint-3) and (min-width: 1025px) {
        margin-top: 8vh;
        margin-bottom: 0;
      }
      @media only screen and (max-width: $mobile) {
        @include columns-change(1);
        width: 100%;
        padding: 0;
        margin: 0;
      }

      &.mobile-header {
        display: none;
        @media only screen and (max-width: $mobile) {
          margin-top: 0px;
          @include columns-change(1);
          padding: 20px 0 0 0;
          margin-bottom: 30px;
          display: block;
        }
      }

      h1 {
        @include columns(1);
        @include headline-title;
        @extend %effect;
        color: $dark-blue-new;
        margin-top: 30px;
        &:after {
          left: 10px;
        }
        &.in {
          @extend %in;
        }
        span {
          @include columns-pure(1, 2);
          margin-left: -3px;
        }

        @media only screen and (max-height: $breakpoint-1) and (min-width: 1025px) {
          margin-top: 20px;
          margin-top: 3vh;

          span {
            @include columns-change(1);
          }
        }
        @media only screen and (max-height: $breakpoint-2) and (min-width: 1025px) {
          font-size: 45px;
          margin-bottom: 45px;

          &:after {
            bottom: -24px;
          }
        }
        @media only screen and (max-height: $breakpoint-3) and (min-width: 1025px) {
          font-size: 40px;
          margin-top: 5px;
          margin-bottom: 40px;

          &:after {
            bottom: -20px;
          }
        }
        @media only screen and (max-width: $mobile) {
          padding: 0;
          margin: 41px 0 29px 0;
          letter-spacing: -1px;
          span {
            @include columns-change(1);
          }
        }
      }
      p {
        @include columns(1, 2);
        @include paragraph;
        padding-right: 70px;
        margin: 0 0 20px 0;
        @extend %effect;

        @media only screen and (max-width: $mobile) {
          @include columns-change(1);
          padding-right: 0;
          margin: 0;

          span {
            display: none;
          }
        }
        @media only screen and (max-width: $mobile-small) {
          padding-right: 0;
          margin: 0 0 10px 0;
        }
        @media only screen and (max-height: $breakpoint-2) and (min-width: 1025px) {
          line-height: 25px;
          margin-bottom: 10px;
        }
        &.in {
          @extend %in;
        }
      }
      a {
        @media only screen and (max-width: $mobile) {
          //padding: 0 0 0 15px;

          &:before {
            top: 2px;
            font-size: 14px;
          }
        }
        &:before {
          color: $red;
        }
      }
    }
    .subtext-link-box {
      @include columns(1);
      margin: 10px 0 0 0;
      @extend %effect;
      @media only screen and (max-height: $breakpoint-1) and (min-width: 1025px) {
        margin-top: 0;
      }
      @media only screen and (max-width: $mobile) {
        margin-top: 0;
      }

      &.in {
        @extend %in;
      }
    }
    .key-number-list {
      @include columns(1);
      margin-bottom: 80px;
      margin-bottom: 10vh;

      @extend %effect;

      &.in {
        @extend %in;
      }

      @media only screen and (max-width: $mobile) {
        @include columns-change(1);
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
        margin-bottom: 0;
        border-left: 0;
        padding: 40px 20px;
        transform: translate3d(0, 100%, 0);
        background-image: url('../images/backgrounds/bkg-section-5-mobile.png');
        background-color: $bkg-grey;
        background-size: 100%;
        background-position: center bottom;
        background-repeat: no-repeat;
      }

      .key-number {
        //@include columns-pure(2);
        width: 33.33%;
        float: left;
        @extend %effect;
        color: $dark-blue-new;
        position: relative;
        border: 1px solid transparent;

        span {
          float: left;
        }

        .text {
          font-size: 22px;
          line-height: 26px;
          font-family: $font-vdk-semiBold;
          clear: both;
        }

        &.in {
          @extend %in;
        }

        @media only screen and (max-width: $mobile) {
          @include columns-change(1);
          display: table;
          width: 100%;
          //height: 50%;
          height: 30%;
          float: none;
          margin: 0;
          padding: 0px;
        }

        .key-number-inner {
          display: table;
          margin: 0 auto;
          position: relative;

          @media only screen and (max-width: $mobile) {
            display: table-cell;
            vertical-align: middle;
          }
        }
        img {
          display: block;
          margin: 0 auto;
          max-height: 217px;

          @media only screen and (max-width: $mobile) {
            width: 80%;
            max-width: 225px;
            max-height: none;
          }
        }
        .number {
          //font-size: 70px;
          //line-height: 73px;
          letter-spacing: -2px;
          font-weight: 800;
          text-align: left;
          font-family: $font-vdk-extraBold;
          font-size: 82px;
          line-height: 69px;
          color: $dark-blue-new;

          .num {
            float: left;
          }

          @media only screen and (max-width: $tablet) {
            @include columns-change(1);
            font-size: 66px;
            line-height: 69px;
          }
        }

        &.in {
          @media only screen and (max-width: $mobile) {
            opacity: 1;
            transform: none;
          }
          @media only screen and (max-width: $mobile +1) {
            opacity: 1;
            transform: none;
          }
        }
      }
    }
  }
}
