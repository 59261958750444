#markets {
  background-color: $light-blue;
  overflow: hidden;
  .pin-mobile-title,
  .map-image-mobile {
    display: none;
  }
  .page-title {
    @include page-title;
    color: $dark-blue-new;
    right: 8px;
    top: 57px;
  }

  .main-headline {
    @include headline-title;
    color: $dark-blue-new;
    position: absolute;
    top: 5%;
    left: 10%;
    font-size: 50px;
    line-height: 53px;
    letter-spacing: -2px;
    text-indent: -3px;
    font-weight: 800;
    z-index: 1;
    transition: left 1s ease, opacity 1s ease;

    &.in {
      left: 30%;
      opacity: 0;
    }

    @media only screen and (max-height: 640px) and (min-width: 1025px) {
      left: 5%;
    }

    @media only screen and (max-width: $mobile) {
      position: static;
      font-size: 26px;
      line-height: 1.2em;
      padding: 0 20px;
      margin: 3px 0 3px 0;
      letter-spacing: -1px;
    }
  }
  .main-text {
    @include paragraph;
    position: absolute;
    max-width: 28%;
    font-size: 16px;
    line-height: 28px;
    top: 16%;
    left: 10%;
    z-index: 1;
    transition: left 1s ease, opacity 1s ease;

    &.in {
      left: 30%;
      opacity: 0;
    }

    @media only screen and (min-height: 710px) and (max-height: 900px) { 
      span, br {
        display: none;
      }
    }

    @media only screen and (max-height: 900px) and (min-width: 1025px) {
      span, br {
        display: none;
      }
    }
  }
  .fp-slidesNav {
    display: none !important;
  }
  .fp-tableCell {
    position: relative;
  }
  .markets-map {
    position: absolute;
    top: 0;
    left: 23.5%;
    width: 76.5%;
    transition: transform 1000ms ease;
    transform: translateX(-20%);

    @media only screen and (max-width: ($tablet - 1)) {
      transform: none;
      width: 100%;
      left: 0;
    }

    .map-image-wrapper {
      position: relative;
      .map-image {
        display: block;
        width: 150%;
        margin-left: -25%;
        height: auto;
      }
      .pins {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 2;
        li {
          cursor: pointer;
          position: absolute;
          z-index: 1;
          margin-top: -6px;
          margin-left: -6px;
          height: 39px;
          width: 39px;
          border-radius: 100%;
          box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.25);
          transition: color 400ms ease, transform 400ms cubic-bezier(0.32, 3, 0.9, 0.69), opacity 0.3s ease;
          opacity: 0;
          transform: translateY(15px);
          &:nth-child(3n + 4) {
            clear: left;
          }

          &.in {
            opacity: 1;
            transform: translateY(0px);
          }
          &:after {
            content: "";
            display: block;
            height: 62px;
            width: 45px;
            top: -3px;
            left: -3px;
            position: absolute;
            background-image: url(../images/svg/map_pin.svg);
            background-size: contain;
            background-repeat: no-repeat;
            background-color: transparent;
            transform: scale(0) translateY(50px);
            transition: transform 0.3s ease;
          }
          &:hover {
            color: $dark-blue;
          }
          &.active {
            color: $dark-blue;
            &:after {
              transform: scale(1) translateY(0px);
            }
          }

          svg,
          img {
            z-index: 1;
            width: 100%;
            height: 100%;
            position: relative;
            border-radius: 100%;
            border: 2px solid #fff;
            background: #fff;
          }
        }
      }
    }
    &.in {
      transform: none;
    }
  }
  .markets-info {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    // width: 30%;
    width: 23.5%;
    min-width: 442px;
    height: 100%;
    background: $bkg-grey;
    color: $dark-blue-new;
    box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.2);
    transition: transform 1000ms ease;
    transform: translateX(-110%);
    @media only screen and (max-width: ($tablet - 1)) {
      width: 100%;
      z-index: 1000;
      //      transform: translateX(100%);
    }
    .slideshow {
      position: relative;
      height: 100%;
    //  padding: 0 15px;

      .arrow-text-wrap {
        // @extend %effect;
        // &.in {
        //   @extend %in;
        // }

        width: 100%;
        bottom: 0px;
        left: 0;
        z-index: 1;
        padding: 0 30px;
        position: absolute;
        background: #fff;

        .arrow-text-inner {
          display: table;
          //height: 83px;
          height: 100px;
          width: 100%;
          //padding: 20px 0 0 0;

          .text-box {
            color: $dark-blue-new;
            font-weight: 700;
            font-size: 14px;
            line-height: 14px;
            max-width: 115px;
            word-break: break-word;
            letter-spacing: -0.5px;
            vertical-align: middle;
            display: table-cell;
            width: 50%;

            &.prev {
              text-align: left;
              padding: 0 15px 0 70px;
            }
            &.next {
              text-align: right;
              padding: 0 70px 0 15px;
            }
          }
        }
      }

      .close-infobox {
        cursor: pointer;
        display: block;
        position: absolute;
        z-index: 1;
        top: 10px;
        right: 10px;
        width: 40px;
        height: 40px;
        padding: 8px;
        border-radius: 100%;
      }

      .fp-controlArrow {
        border: 0;
        margin: 0;
        width: 40%;
        height: 50px;
        top: auto;
        bottom: 20px;
        // @extend %effect;
        // &.in {
        //   @extend %in;
        // }

        width: 57px;
        line-height: 55px;
        height: 57px;
        border: 2px solid $red;
        border-radius: 100%;
        text-align: center;

         &:before {
           color: $red;
           @include icons-fonts;
           font-size: 23px;
           font-weight: 100;

        }
        &.fp-prev {
          left: 30px;

          &:before {
            content: "\ea40";
          }
        }
        &.fp-next {
          left: auto;
          right: 30px;
          &:before {
            content: "\ea3c";
          }
        }
      }

      .slide {
        .fp-tableCell {
          vertical-align: top;
        }
        .country-description {
          margin: $grid-gutter * 2.5 0 $grid-gutter * 2 0;
          padding: 0 $grid-gutter * 3.5;

          img {
            width: 62px;
            vertical-align: middle;
            border: 2px solid $white;
            border-radius: 100%;
            background: $white;
            box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.25);
          }
          h2 {
            color: #1f4a5e;
            display: inline-block;
            font-weight: 900;
            font-size: 25px;
            line-height: 1em;
            vertical-align: middle;
            padding: 0 0 2px 13px;
          }
        }
        .main-image-box {
          padding: $grid-gutter / 2 0;
          background: $dark-blue-new;
          img {
            height: 300px;
            margin: 0 auto;
            display: block;
          }
        }
        .text-wrap {
          padding: 0 $grid-gutter * 3.5;

          p {
            margin-top: 7px;
            line-height: 1.4em;
            font-size: 14px;
            &:first-child {
              margin-top: 0;
              margin-bottom: $grid-gutter * 2;
              color: $light-blue-text;
            }
          }
        }
      }
    }
    &.in {
      transform: none;
    }
  }

  @media only screen and (max-width: $mobile) {
    .page-title {
      position: absolute;
      margin: 0;
      left: 20px;
      top: 14px;
    }
    .map-image-mobile {
      display: block;
      position: absolute;
      width: 210%;
      left: -43%;
      top: 19%;
      opacity: 0.5;
    }
    .main-headline,
    .main-text {
      display: none;
    }
    .markets-map {
      height: 100%;
      position: static;
      display: table;

      .map-image-wrapper {
        padding: 0 20px 10px 20px;
        vertical-align: middle;
        display: table-cell;

        .map-image {
          display: none;
        }
        .pins {
          display: table;
          position: static;
          height: auto;

          li {
            position: static;
            float: left;
            width: 33.3333%;
            height: auto;
            box-shadow: none;
            margin: 10px 0 0 0;

            .pin-mobile-title {
              color: $dark-blue-new;
              display: block;
              text-align: center;
              text-transform: uppercase;
              height: 36px;
              font-weight: 900;
              font-size: 11px;
              word-break: break-word;
              padding: 0 10px;
              margin: 10px 0 0 0;
            }

            svg,
            img {
              width: 63%;
              height: auto;
              margin: 0 auto;
              display: block;
            }

            &:after {
              display: none;
            }
          }
        }
      }
    }

    .markets-info {
      min-width: auto;
      transition-duration: 0.5s;

      .slideshow {
        padding: 0;

        .close-infobox {
          top: 0;
          right: 0;
        }
        .slide {
          opacity: 0;
          transition: opacity 0.4s ease;
          &.active {
            opacity: 1;
          }

          .fp-tableCell {
            padding: 0;
          }
          .country-description {
            margin: 0 0 2vh 0;

            h2 {
              font-size: 18px;
            }
            img {
              width: 80px;
              margin-top: -40px;
            }
          }
          .main-image-box {
            img {
              height: 160px;
            }
          }
          .text-wrap {
            padding: 4vh $space * 3.3 0 $space * 3.3;

            p {
              font-size: 13px;
              line-height: 18px;

              span {
                display: none;
              }
            }
          }
        }

        .arrow-text-wrap {
          padding: 0;
          .arrow-text-inner {
            height: 66px;
            .text-box {
              font-weight: 700;
              font-size: 13px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              &.prev {
                padding-left: 84px;
              }
              &.next {
                padding-right: 84px;
              }
            }
          }
        }

        .fp-controlArrow {
          height: 40px;
          width: 40px;
          bottom: 15px;
          line-height: 38px;

          &:before {
            font-size: 18px;
            vertical-align: middle;
          }
        }
      }
    }
  }

  @media only screen and (max-height: 690px) and (min-width: 1025px) {
    .markets-info {
      min-width: 380px;
      .slideshow .slide .text-wrap {
        font-size: 2.2vh;
        padding: 20px 35px 0 35px;

        p {
          font-size: 14px;
        }
      }
      .slideshow .fp-controlArrow {
        bottom: 40px;
      }
      .slideshow .arrow-text-wrap .arrow-text-inner {
        height: 68px;
      }
      .slideshow .slide .country-description img {
        width: 48px;
      }
      .slideshow .slide .country-description {
        margin: 10px 0;
      }
    }
  }

  @media only screen and (max-height: 800px) and (min-width: 1025px) {
    .markets-info .slideshow .slide .main-image-box img {
      height: 19vh;
      min-height: 110px;
    }
    .markets-info .slideshow .slide .text-wrap {
      line-height: 1.4em;
    }
  }
}
