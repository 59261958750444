@font-face {
  font-family: "icons";
  src: url("../icons-fonts/icons.eot");
  src: url("../icons-fonts/icons.eot#iefix") format("embedded-opentype"), url("../icons-fonts/icons.woff2") format("woff2"),
    url("../icons-fonts/icons.woff") format("woff"), url("../icons-fonts/icons.ttf") format("truetype"), url("../icons-fonts/icons.svg#icons") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'icons';
    src: url('../icons-fonts/icons.svg#icons') format('svg');
  }
}
*/


[class^="icon-"], [class*=" icon-"] {
  // display: inline-block;
  // text-decoration: inherit;
  // width: 1em;
  // margin-right: 0.2em;
  // text-align: center;
  /* fix buttons height, for twitter bootstrap */
  ///line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  //margin-left: 0.2em;

  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin icons-fonts {
  font-family: 'icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-up2:before {
  content: "\ea3a";
}
.icon-arrow-right2:before {
  content: "\ea3c";
}
.icon-arrow-down2:before {
  content: "\ea3e";
}
.icon-arrow-left2:before {
  content: "\ea40";
}
.icon-down-open:before {
  content: "\e800";
}
.icon-left-open:before {
  content: "\e801";
}
.icon-right-open:before {
  content: "\e802";
}
.icon-up-open:before {
  content: "\e803";
}
.icon-play-circled2:before {
  content: "\e804";
}
.icon-play-circled:before {
  content: "\e805";
}
.icon-mail-circled:before {
  content: "\e806";
}
.icon-custom-mail:before {
  content: "\e80e";
}
.icon-custom-pin:before {
  content: "\e80f";
}
.icon-custom-play:before {
  content: "\e810";
}
.icon-location-1:before {
  content: "\f031";
}
.icon-angle-left:before {
  content: "\f104";
}
.icon-angle-right:before {
  content: "\f105";
}
.icon-angle-up:before {
  content: "\f106";
}
.icon-angle-down:before {
  content: "\f107";
}
.icon-twitter-circled:before {
  content: "\f30a";
}
.icon-facebook-circled:before {
  content: "\f30d";
}
.icon-linkedin-circled:before {
  content: "\f319";
}
.icon-facebook:before {
  content: "\e900";
}
